import { ROUTES } from "../routes";

export const mapRoutes: any = {
  ide: ROUTES.IDE.path,
  ingestion: ROUTES.Ingestion.path,
  jobs: ROUTES.Jobs.path,
  bi: ROUTES.BI.path,
  architecture: ROUTES.Architecture.path,
  utilization: ROUTES.Utilization.path,
  team: ROUTES.Team.path,
  SETUP_AUTHENTICATION: ROUTES.SetupAuthentication.path,
};
