export function snakeToCamel(snake: string): string {
  return snake
    .toLowerCase()
    .replace(/(_\w)/g, (match) => match[1].toUpperCase());
}

export function camelToSnake(camel: string): string {
  return camel.replace(/([A-Z])/g, "_$1").toUpperCase();
}

export function camelToSnakeUpperCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Insert underscore between lower and upper case letters
    .replace(/([A-Z])([A-Z][a-z])/g, "$1_$2") // Insert underscore between uppercase letters followed by lowercase letters
    .toUpperCase();
}
