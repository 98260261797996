export const iconPath: { [key: string]: string } = {
  // info: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}info.svg`,
  // "hide-password": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}hide-password.svg`,
  // "show-password": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}show-password.svg`,
  // google: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}google.svg`,
  // FivexLogoWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5x-logo-white.svg`,
  // FivexIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5X-icon.svg`,
  // signUpBackground: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}signup-background.png`,
  // author: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}author.svg`,
  // "onboarding-background": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding-background.png`,
  // "login-with-google": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}login-with-google.svg`,
  // setup: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}set-up.gif`,
  // backButton: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}back-btn.svg`,
  // glossyCoupon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}glassy-coupon.svg`,
  // radioChecked: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}radio-checked.svg`,
  // eyeClosed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}eye-closed.svg`,
  // eyeOpen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}eye-open.svg`,
  // checkedCheckbox: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}checked-checkbox.svg`,
  // defaultCheckbox: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}default-checkbox.svg`,
  // arrowRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arrow-right.svg`,
  // collapse: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}collapse.svg`,
  // expand: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}expand.svg`,
  // ide: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide.svg`,
  // ingestion: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion.svg`,
  // jobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs.svg`,
  // bi: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}bi.svg`,
  // architecture: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture.svg`,
  // utilization: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization.svg`,
  // team: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}team.svg`,
  // security: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}security.svg`,
  // settings: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settingsNav.svg`,
  // alerts: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts.svg`,
  // help: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}help.svg`,
  // snowflake: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}snowflake.svg`,
  // bigQuery: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}big-query.svg`,
  // success: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}success.svg`,
  // error: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}error.svg`,
  // warning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}warning.svg`,
  // plus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plus.svg`,
  // appConnection: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}appConnection.svg`,
  // appConnectionActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}appConnectionActive.svg`,
  // credential: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}credential.svg`,
  // credentialActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}credentialActive.svg`,
  // env: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}env.svg`,
  // key: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}key.svg`,
  // keyActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}keyActive.svg`,
  // githubSmall: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}github-logo.svg`,
  // secrets: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}secrets.svg`,
  // alertsSmall: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts-small.svg`,
  // billing: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}billing-icon-small.svg`,
  // profile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}profile-icon-small.svg`,
  // profileActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}profileActive.svg`,
  // logout: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}logout.svg`,
  // plug: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plug-icon.svg`,
  // sync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Sync.svg`,
  // reSync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Re-sync.svg`,
  // pause: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Pause.svg`,
  // trash: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Trash.svg`,
  // resume: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Play.svg`,
  // edit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Edit-setup.svg`,
  // tableAction: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}tableAction.svg`,
  // deleteWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete-white.svg`,
  // deleteGrey: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete-grey.svg`,
  // userPlus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}user-plus.svg`,
  // delete_head: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete_head.svg`,
  // noUser: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}no-user.svg`,
  // fiveXLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5X_Logo.svg`,
  // plugWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plug-white.svg`,
  // plugBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plug-black.svg`,
  // activeDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}active-dot.svg`,
  // brokenDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}borken-dot.svg`,
  // threeDotMenu: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}three-dot-menu.svg`,
  // editIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}edit.svg`,
  // redDelete: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}red-delete.svg`,
  // duplicate: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}duplicate.svg`,
  // editWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}white-pencil.svg`,
  // checkWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Check.svg`,
  // addCredential: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-credntial.svg`,
  // testLoader: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}test-loader.svg`,
  // testSetup: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}test-setup.svg`,
  // passedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}passed-icon.svg`,
  // failedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}failed-icon.svg`,
  // ArrowRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arraow-right.svg`,
  // filter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}filter.svg`,
  // "filter-applied": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}filter-applied.svg`,
  // "sort-asc": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-asc.svg`,
  // "sort-desc": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-desc.svg`,
  // closeLight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}close-light.svg`,
  // dbIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}db-ide.svg`,
  // folderIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-ide.svg`,
  // gitIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}git-ide.svg`,
  // dbIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}db-icon.svg`,
  // schemaIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}schema-icon.svg`,
  // tableIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}table-icon.svg`,
  // viewIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}view-icon.svg`,
  // refreshIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}refresh-datasource.svg`,
  // dbIdeInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}database-inactive.svg`,
  // folderIdeActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-active.svg`,
  // collapsedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}collapsed.svg`,
  // expandedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}expanded.svg`,
  // reload: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}reload.svg`,
  // addFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-file.svg`,
  // addFolder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-folder.svg`,
  // folderOpen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-open.svg`,
  // folderClosed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-closed.svg`,
  // sqlFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sql-file.svg`,
  // newFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}new-file.svg`,
  // newFolder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}new-folder.svg`,
  // addFileTab: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-file-tab.svg`,
  // closeFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}close-file.svg`,
  // fileTab: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}file-tab.svg`,
  // role: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}role.svg`,
  // warehouse: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}warehouse.svg`,
  // run: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}run.svg`,
  // whiteDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}white-down.svg`,
  // search: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}search-input.svg`,
  // arrowRightDark: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ArrowRight.svg`,
  // syncBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}SyncNowBlue.svg`,
  // plugBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}PlugChargingBlue.svg`,
  // pauseBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}PauseBlue.svg`,
  // reloadConnector: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Reload.svg`,
  // settingsNormal: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settingsnormal.svg`,
  // close: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}close.svg`,
  // "delete-white": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete-white.svg`,
  // info_head: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}info_head.svg`,
  // playBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}PlayBlue.svg`,
  // fivexLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5X_Logo.svg`,
  // successCheck: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}CheckCircle.svg`,
  // caretDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}CaretDown.svg`,
  // primaryKey: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Key.svg`,
  // initialSync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}initial-sync.svg`,
  // save: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}save.svg`,
  // editProfilePic: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}edit-profile-pic.svg`,
  // arrowCircleRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arrow-circle-right.svg`,
  // trialOver: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-over.svg`,
  // trial20: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-20.svg`,
  // trial2040: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-20-40.svg`,
  // trial40: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-40.svg`,
  // trial: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial.svg`,
  // georgeFerreira: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}george-ferreira.svg`,
  // steveHibberd: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}steve-hibberd.svg`,
  // novo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}novo.svg`,
  // monicaVinader: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}monica-vinader.svg`,
  // amalan: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}amalan.svg`,
  // tripScout: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trip-scout.svg`,
  // launchMetrics: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}launch-metrics.svg`,
  // cupbop: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}cupbop.svg`,
  // topShelfInternational: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}top-shelf-international.svg`,
  // xap: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}xap.svg`,
  // checkBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}CheckBlack.svg`,
  // teamsBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-black.svg`,
  // teamsNodata: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-nodata.svg`,
  // invite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}invitation-pending.svg`,
  // infoRed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}info-red.svg`,
  // greenCheck: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}green-check.svg`,
  // redCross: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}red-cross.svg`,
  // readOnly: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}read-only.svg`,
  // resultTabsList: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}result-tabs-list.svg`,
  // downloadResults: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}download-results.svg`,
  // selectionResults: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}selection-results.svg`,
  // greenDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}green-dot.svg`,
  // redDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}red-dot.svg`,
  // pinned: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}pinned.svg`,
  // tabsIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}tabs-icon.svg`,
  // enabledTabs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}enabledTabs.svg`,
  // copyIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}copy-icon.svg`,
  // formatSql: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}format-sql.svg`,
  // abortQuery: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}abort-query.svg`,
  // rowsCount: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}rows-count.svg`,
  // synced: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}synced.svg`,
  // selectedUser: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}selected-user.svg`,
  // placeholderIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}placeholder-ide.svg`,
  // download: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}download.svg`,
  // chartBar: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}chart-bar.svg`,
  // transformation: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}transformation.svg`,
  // cloud: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}cloud.svg`,
  // source: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}source.svg`,
  // fivexPink: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}fivex-pink.svg`,
  // fivexPurple: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}fivex-purple.svg`,
  // fivexGreen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}fivex-green.svg`,
  // circle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}circle.svg`,
  // sourceLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}source-logo.svg`,
  // transportLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}transport-logo.svg`,
  // storageLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}storage-logo.svg`,
  // transformationLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}transformation-logo.svg`,
  // businessIntelligenceLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}business-intelligence-logo.svg`,
  // productAnalyticsLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}product-analytics-logo.svg`,
  // aiLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ai-logo.svg`,
  // arrowUpRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arrow-up-right.svg`,
  // minus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}minus.svg`,
  // arrowCounterClockwise: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ArrowCounterClockwise.svg`,
  // magnifyingGlassMinus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}MagnifyingGlassMinus.svg`,
  // magnifyingGlassPlus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}MagnifyingGlassPlus.svg`,
  // connectorPlug: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}connector-plug.svg`,
  // copy: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}copy.svg`,
  // inactiveSort: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}inactive-sort.svg`,
  // sortUp: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-up.svg`,
  // sortDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-down.svg`,
  // inactiveFilter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}inactive-filter.svg`,
  // activeFilter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}active-filter.svg`,
  // errorCircle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}error-circle.svg`,
  // warningTriangle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}warning-triangle.svg`,
  // outsideFiveX: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}outside-5x-placeholder.svg`,
  // scriptRunning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}script-running.gif`,
  // editAlert: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}edit-alert.svg`,
  // accessInfo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-access-info.svg`,
  // accessWarning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-access-warning.svg`,
  info: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}info.svg`,
  "hide-password": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}hide-password.svg`,
  "show-password": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}show-password.svg`,
  google: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}google.svg`,
  FivexLogoWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5x-logo-white.svg`,
  FivexIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5X-icon.svg`,
  signUpBackground: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}signup-background.png`,
  author: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}author.svg`,
  "onboarding-background": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding-background.png`,
  "login-with-google": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}login-with-google.svg`,
  setupComplete: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}set-up-complete.gif`,
  setup: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}set-up.gif`,
  backButton: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}back-btn.svg`,
  glossyCoupon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}glassy-coupon.svg`,
  radioChecked: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}radio-checked.svg`,
  eyeClosed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}eye-closed.svg`,
  eyeOpen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}eye-open.svg`,
  checkedCheckbox: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}checked-checkbox.svg`,
  defaultCheckbox: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}default-checkbox.svg`,
  arrowRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arrow-right.svg`,
  collapse: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}collapse.svg`,
  expand: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}expand.svg`,
  ide: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide.svg`,
  ingestion: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion.svg`,
  jobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs.svg`,
  bi: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}bi.svg`,
  architecture: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture.svg`,
  utilization: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization.svg`,
  team: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}team.svg`,
  security: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}security.svg`,
  settings: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settingsNav.svg`,
  alerts: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts.svg`,
  help: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}help.svg`,
  snowflake: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}snowflake.svg`,
  bigQuery: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}big-query.svg`,
  success: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}success.svg`,
  error: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}error.svg`,
  warning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}warning.svg`,
  plus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plus.svg`,
  appConnection: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}appConnection.svg`,
  appConnectionActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}appConnectionActive.svg`,
  credential: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}credential.svg`,
  credentialActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}credentialActive.svg`,
  env: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}env.svg`,
  key: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}key.svg`,
  keyActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}keyActive.svg`,
  githubSmall: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}github-logo.svg`,
  secrets: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}secrets.svg`,
  alertsSmall: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts-small.svg`,
  billing: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}billing-icon-small.svg`,
  profile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}profile-icon-small.svg`,
  profileActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}profileActive.svg`,
  logout: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}logout.svg`,
  plug: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plug-icon.svg`,
  sync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Sync.svg`,
  reSync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Re-sync.svg`,
  pause: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Pause.svg`,
  trash: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Trash.svg`,
  resume: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Play.svg`,
  edit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Edit-setup.svg`,
  tableAction: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}tableAction.svg`,
  deleteWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete-white.svg`,
  deleteGrey: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete-grey.svg`,
  userPlus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}user-plus.svg`,
  delete_head: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete_head.svg`,
  noUser: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}no-user.svg`,
  fiveXLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5X_Logo.svg`,
  plugWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plug-white.svg`,
  plugBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}plug-black.svg`,
  activeDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}active-dot.svg`,
  brokenDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}borken-dot.svg`,
  threeDotMenu: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}three-dot-menu.svg`,
  editIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}edit.svg`,
  redDelete: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}red-delete.svg`,
  duplicate: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}duplicate.svg`,
  editWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}white-pencil.svg`,
  checkWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Check.svg`,
  addCredential: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-credntial.svg`,
  testLoader: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}test-loader.svg`,
  testSetup: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}test-setup.svg`,
  passedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}passed-icon.svg`,
  failedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}failed-icon.svg`,
  ArrowRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arraow-right.svg`,
  filter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}filter.svg`,
  "filter-applied": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}filter-applied.svg`,
  "sort-asc": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-asc.svg`,
  "sort-desc": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-desc.svg`,
  closeLight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}close-light.svg`,
  dbIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}db-ide.svg`,
  folderIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-ide.svg`,
  gitIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}git-ide.svg`,
  dbIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}db-icon.svg`,
  schemaIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}schema-icon.svg`,
  tableIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}table-icon.svg`,
  viewIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}view-icon.svg`,
  refreshIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}refresh-datasource.svg`,
  dbIdeInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}database-inactive.svg`,
  folderIdeActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-active.svg`,
  gitIdeActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}gitIdeActive.svg`,
  collapsedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}collapsed.svg`,
  expandedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}expanded.svg`,
  reload: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}reload.svg`,
  addFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-file.svg`,
  addFolder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-folder.svg`,
  folderOpen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-open.svg`,
  folderClosed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}folder-closed.svg`,
  sqlFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sql-file.svg`,
  newFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}new-file.svg`,
  newFolder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}new-folder.svg`,
  addFileTab: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-file-tab.svg`,
  closeFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}close-file.svg`,
  fileTab: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}file-tab.svg`,
  role: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}role.svg`,
  warehouse: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}warehouse.svg`,
  run: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}run.svg`,
  whiteDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}white-down.svg`,
  search: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}search-input.svg`,
  arrowRightDark: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ArrowRight.svg`,
  syncBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}SyncNowBlue.svg`,
  plugBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}PlugChargingBlue.svg`,
  pauseBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}PauseBlue.svg`,
  reloadConnector: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Reload.svg`,
  settingsNormal: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settingsnormal.svg`,
  close: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}close.svg`,
  "delete-white": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}delete-white.svg`,
  info_head: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}info_head.svg`,
  playBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}PlayBlue.svg`,
  fivexLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5X_Logo.svg`,
  successCheck: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}CheckCircle.svg`,
  caretDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}CaretDown.svg`,
  primaryKey: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}Key.svg`,
  initialSync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}initial-sync.svg`,
  save: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}save.svg`,
  editProfilePic: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}edit-profile-pic.svg`,
  arrowCircleRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arrow-circle-right.svg`,
  trialOver: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-over.svg`,
  trial20: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-20.svg`,
  trial2040: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-20-40.svg`,
  trial40: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial-40.svg`,
  trial: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial.svg`,
  georgeFerreira: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}george-ferreira.svg`,
  steveHibberd: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}steve-hibberd.svg`,
  novo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}novo.svg`,
  monicaVinader: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}monica-vinader.svg`,
  amalan: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}amalan.svg`,
  tripScout: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trip-scout.svg`,
  launchMetrics: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}launch-metrics.svg`,
  cupbop: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}cupbop.svg`,
  topShelfInternational: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}top-shelf-international.svg`,
  xap: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}xap.svg`,
  dotError600: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}dot-error-600.svg`,
  dotWarning600: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}dot-warning-600.svg`,
  dotSuccess600: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}dot-success-600.svg`,
  checkBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}CheckBlack.svg`,
  teamsBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-black.svg`,
  teamsNodata: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-nodata.svg`,
  invite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}invitation-pending.svg`,
  infoRed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}info-red.svg`,
  greenCheck: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}green-check.svg`,
  redCross: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}red-cross.svg`,
  readOnly: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}read-only.svg`,
  resultTabsList: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}result-tabs-list.svg`,
  downloadResults: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}download-results.svg`,
  selectionResults: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}selection-results.svg`,
  greenDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}green-dot.svg`,
  redDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}red-dot.svg`,
  pinned: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}pinned.svg`,
  tabsIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}tabs-icon.svg`,
  enabledTabs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}enabledTabs.svg`,
  copyIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}copy-icon.svg`,
  formatSql: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}format-sql.svg`,
  abortQuery: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}abort-query.svg`,
  rowsCount: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}rows-count.svg`,
  synced: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}synced.svg`,
  selectedUser: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}selected-user.svg`,
  placeholderIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}placeholder-ide.svg`,
  download: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}download.svg`,
  chartBar: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}chart-bar.svg`,
  transformation: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}transformation.svg`,
  cloud: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}cloud.svg`,
  source: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}source.svg`,
  fivexPink: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}fivex-pink.svg`,
  fivexPurple: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}fivex-purple.svg`,
  fivexGreen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}fivex-green.svg`,
  circle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}circle.svg`,
  sourceLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}source-logo.svg`,
  transportLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}transport-logo.svg`,
  storageLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}storage-logo.svg`,
  transformationLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}transformation-logo.svg`,
  businessIntelligenceLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}business-intelligence-logo.svg`,
  productAnalyticsLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}product-analytics-logo.svg`,
  aiLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ai-logo.svg`,
  arrowUpRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}arrow-up-right.svg`,
  minus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}minus.svg`,
  arrowCounterClockwise: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ArrowCounterClockwise.svg`,
  magnifyingGlassMinus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}MagnifyingGlassMinus.svg`,
  magnifyingGlassPlus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}MagnifyingGlassPlus.svg`,
  connectorPlug: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}connector-plug.svg`,
  copy: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}copy.svg`,
  inactiveSort: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}inactive-sort.svg`,
  sortUp: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-up.svg`,
  sortDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-down.svg`,
  inactiveFilter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}inactive-filter.svg`,
  activeFilter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}active-filter.svg`,
  errorCircle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}error-circle.svg`,
  warningTriangle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}warning-triangle.svg`,
  outsideFiveX: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}outside-5x-placeholder.svg`,
  fivexManagedRepo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5x-managed-repo.svg`,
  addFolderStructure: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-folder-structure.svg`,
  caretDownTree: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}caret-down.svg`,
  dbtLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}dbt-logo.svg`,
  gitBranchActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}git-branch-active.svg`,
  gitBranch: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}git-branch.svg`,
  githubActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}github-active.svg`,
  githubLogoInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}github-logo-inactive.svg`,
  githubRepo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}github-repo.svg`,
  ymlFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}yml-file.svg`,
  tickSelected: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}tick-selected.svg`,
  addedGit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}added-git.svg`,
  deletedGit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}deleted-git.svg`,
  modifiedGit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}modified-git.svg`,
  commitedRepo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}commited-repo.svg`,
  gitCommit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}git-commit.svg`,
  gitCOmmitWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}git-commit-white.svg`,
  revertFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}revert-file.svg`,
  pyFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}py-file.svg`,
  filesCommon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}files-common.svg`,
  revertWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}revertWhite.svg`,
  jobsPlaceholder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs-placeholder.svg`,
  editAlert: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}edit-alert.svg`,
  accessInfo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-access-info.svg`,
  accessWarning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams-access-warning.svg`,
  instance: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}instance.svg`,
  ach: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ach.svg`,
  addJobsNode: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}add-jobs-node.svg`,
  caretLeftJobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}caret-left-jobs.svg`,
  clickNodeJobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}click-node-jobs.svg`,
  errorCircleJobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}error-circle-jobs.svg`,
  hardDrivesJobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}hard-drives.svg`,
  ingeationJobsNode: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingeation-jobs-node.svg`,
  modelingJobsNode: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}modeling-jobs-node.svg`,
  pyJobsNode: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}py-jobs-node.svg`,
  sortDownJobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}sort-down-jobs.svg`,
  successCircle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}success-circle.svg`,
  codeApplied: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}code-applied-check.svg`,
  invalidCode: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}invalid-code.svg`,
  screenOptimization: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}screen-optimization-icon.svg`,
};
