import { Divider } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import NavbarHeader from "./navHeader";
import {
  NavigationsBottom,
  NavigationsMiddle,
  NavigationsTop,
} from "../../constants/navigation";
import Navigations from "./navigations";
import { NavbarWrapper } from "./styled";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../redux/userSlice";
import TrialComponent from "./trial";
import { emitter } from "../../utils/emitter";
import { emitterTypes, featureFlags } from "../../utils/types";
import { useFeatureManager } from "@/utils/feature-flag-manager";

type NavigationItem = {
  name: string;
  icon: string;
  redirectTo: string;
  enabled: boolean;
  id: string;
  type?: string;
};

const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const handleHover = (value: boolean) => {
    dispatch(setNavigation({ navbarHovered: value }));
  };

  const [updatedNavigationsBottom, setUpdatedNavigationsBottom] =
    useState(NavigationsBottom);
  // eslint-disable-next-line
  const [updatedNavigationsTop, setUpdatedNavigationsTop] =
    useState(NavigationsTop);

  const [updatedNavigationsApps, setUpdatedNavigationsApps] = useState<
    NavigationItem[]
  >([]);

  const [updatedNavigationsMiddle, setUpdatedNavigationsMiddle] =
    useState(NavigationsMiddle);

  const companyDetails =
    useSelector((state: { user: any }) => state.user.companyDetails)
      ?.companyDetails || {};

  const workspaceId = companyDetails?.workspace?.id || "";

  const attributes = useMemo(() => ({ workspaceId }), [workspaceId]);

  const { IDE_SHOW_STREAMLIT } = featureFlags;
  const { isFeatureEnabled } = useFeatureManager(attributes);

  const jumpstartEnabled = companyDetails?.showJumpStartIcon;

  const showDocsOnNavbar = useSelector(
    (state: { user: any }) => state.user.showDocsOnNavbar,
  ).showDocsOnNavbar;

  const showDataAppsOnNavbar = useSelector(
    (state: { user: any }) => state.user.showDataAppsOnNavbar,
  )?.showDataAppsOnNavbar;

  useEffect(() => {
    const publishedDataApps = showDataAppsOnNavbar?.map((item: any) => ({
      name: item.appName,
      icon: item.logoUrl,
      redirectTo: `/data-apps/${item.appId}`,
      enabled: true,
      id: item.appId,
      type: "dataApp",
    }));

    const dataAppsFeatureItem = isFeatureEnabled(
      IDE_SHOW_STREAMLIT?.key,
      IDE_SHOW_STREAMLIT?.fallback,
    )
      ? [
          {
            name: "Data Apps",
            icon: "dataApps",
            redirectTo: "/data-apps",
            enabled: true,
            id: "data_apps",
          },
        ]
      : [];

    const dataAppsUpdated = [...dataAppsFeatureItem, ...publishedDataApps];

    setUpdatedNavigationsApps(dataAppsUpdated);
  }, [
    isFeatureEnabled(IDE_SHOW_STREAMLIT?.key, IDE_SHOW_STREAMLIT?.fallback),
    showDataAppsOnNavbar,
  ]);

  useEffect(() => {
    if (showDocsOnNavbar) {
      setUpdatedNavigationsMiddle((prev: any) => {
        // Remove any existing "Docs" item if present
        const filteredPrev = prev.filter((item: any) => item.id !== "dbtDocs");

        // Insert "Docs" at the second position
        return [
          filteredPrev[0], // First item (if it exists)
          {
            name: "Docs",
            icon: "dbtDocWhite",
            redirectTo: "/docs",
            enabled: true,
            id: "dbtDocs",
          },
          ...filteredPrev.slice(1), // Rest of the items after the first
        ];
      });
    } else {
      setUpdatedNavigationsMiddle(NavigationsMiddle);
    }
  }, [showDocsOnNavbar]);

  useEffect(() => {
    if (!jumpstartEnabled) {
      handleTrialUpgradeSuccess();
    }
  }, [jumpstartEnabled]);

  const handleTrialUpgradeSuccess = () => {
    setUpdatedNavigationsBottom((prev: any) => {
      return prev.filter((item: any) => item.id !== "jumpstart");
    });
  };

  useEffect(() => {
    emitter.on(emitterTypes.TRIAL_UPGRADE_SUCCESS, handleTrialUpgradeSuccess);
    return () => {
      emitter.off(
        emitterTypes.TRIAL_UPGRADE_SUCCESS,
        handleTrialUpgradeSuccess,
      );
    };
  }, []);

  return (
    <NavbarWrapper
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <div>
        <NavbarHeader />
        <div className={styles.divider}>
          <Divider />
        </div>
        <Navigations navigationData={updatedNavigationsTop as any} />
        <div className={styles.divider}>
          <Divider />
        </div>
        {updatedNavigationsApps.length && (
          <>
            <Navigations navigationData={updatedNavigationsApps as any} />
            <div className={styles.divider}>
              <Divider />
            </div>
          </>
        )}
        <Navigations navigationData={updatedNavigationsMiddle as any} />
        <TrialComponent />
      </div>
      <div>
        <Navigations navigationData={updatedNavigationsBottom as any} />
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
