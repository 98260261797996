import { createSlice } from "@reduxjs/toolkit";

export const commonStore = createSlice({
  name: "common",
  initialState: {
    tableFilter: [],
    dataWarehouse: null,
    isDownloading: false,
    isDataSourcesAvailable: true,
    dropdownSelected: null,
    warehouse: "snowflake",
  },
  reducers: {
    setTableFilter: (state, action) => {
      if ("tableFilter" in action.payload) {
        state.tableFilter = action.payload;
      }
    },
    setDataWarehouse: (state, action) => {
      if ("dataWarehouse" in action.payload) {
        state.dataWarehouse = action.payload;
      }
    },
    setIsDownloading: (state, action) => {
      if ("isDownloading" in action.payload) {
        state.isDownloading = action.payload;
      }
    },
    setIsDataSourcesAvailable: (state, action) => {
      if ("isDataSourcesAvailable" in action.payload) {
        state.isDataSourcesAvailable = action.payload.isDataSourcesAvailable;
      }
    },
    setDropdownValueSelected: (state, action) => {
      if ("dropdownSelected" in action.payload) {
        state.dropdownSelected = action.payload.dropdownSelected;
      }
    },
    setWarehouse: (state, action: { payload: any }) => {
      if ("warehouse" in action.payload) {
        state.warehouse = action.payload.warehouse;
      }
    },
  },
});

export const {
  setTableFilter,
  setDataWarehouse,
  setIsDownloading,
  setIsDataSourcesAvailable,
  setDropdownValueSelected,
  setWarehouse,
} = commonStore.actions;

export default commonStore.reducer;
