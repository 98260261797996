const AUTH_TOKEN_EXPIRY = 24 * 60 * 60; // 24 hours in seconds

export const storeTokensInCookie = (accessToken: string | undefined) => {
  if (accessToken !== undefined) {
    document.cookie = `accessToken=${accessToken}; Max-Age=${AUTH_TOKEN_EXPIRY}; path=/;`;
  }
};

export const getAccessTokenFromCookie = (): string | null => {
  if (typeof window === "undefined") {
    // This means the code is running on the server, return null or handle accordingly
    return null;
  }

  const cookies = document?.cookie?.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === "accessToken") {
      return value;
    }
  }
  return null;
};

export const getRefreshTokenFromCookie = (): string | null => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === "refreshToken") {
      return value;
    }
  }
  return null;
};

export const clearTokens = () => {
  const deleteCookie = (name: string, path: any) => {
    let cookieString = `${name}=; Max-Age=0;`;
    if (path) cookieString += ` path=${path};`;
    document.cookie = cookieString;
  };

  const paths = location.pathname
    .split("/")
    .map((_, i, arr) => `/${arr.slice(1, i + 1).join("/")}`);

  ["accessToken", "refreshToken"].forEach((name) => {
    paths.forEach((path) => deleteCookie(name, path));
    deleteCookie(name, "/"); // Default path
  });
};
export const setBIInstance = (url: string) => {
  sessionStorage.setItem("biInstance", url);
};
export const getBIInstance = (): string | null => {
  const biInstance = sessionStorage?.getItem("biInstance");
  return biInstance ? biInstance : null;
};

export const setRedirectUrl = (url: string) => {
  const expiryTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes from now
  const item = { url, expiryTime };
  localStorage.setItem("redirectUrl", JSON.stringify(item));
};

export const getRedirectUrl = (): string | null => {
  const redirectUrl = localStorage?.getItem("redirectUrl");

  if (redirectUrl) {
    const { url, expiryTime } = JSON.parse(redirectUrl);
    if (new Date().getTime() < expiryTime) {
      return url;
    } else {
      localStorage.removeItem("redirectUrl"); // Remove expired URL from storage
    }
  }

  return null; // Return null if no URL or if it has expired
};

export const clearRedirectUrl = () => {
  localStorage.removeItem("redirectUrl");
};

export const storeMFATokensInCookie = (mfaToken: string | undefined) => {
  if (mfaToken !== undefined) {
    document.cookie = `mfaToken=${mfaToken}; Max-Age=${AUTH_TOKEN_EXPIRY}; path=/;`;
  }
};

export const getMFATokenFromCookie = (): string | null => {
  if (typeof window === "undefined") {
    // This means the code is running on the server, return null or handle accordingly
    return null;
  }

  const cookies = document?.cookie?.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === "mfaToken") {
      return value;
    }
  }
  return null;
};

export const removeMfaStatus = () => {
  localStorage.removeItem("mfaStatus");
};

export const getMfaStatus = () => {
  return localStorage.getItem("mfaStatus");
};

export const setMfaStatus = (status: string) => {
  localStorage.setItem("mfaStatus", status);
};

export const clearMFATokens = () => {
  const deleteCookie = (name: string, path: any) => {
    let cookieString = `${name}=; Max-Age=0;`;
    if (path) cookieString += ` path=${path};`;
    document.cookie = cookieString;
  };

  const paths = location.pathname
    .split("/")
    .map((_, i, arr) => `/${arr.slice(1, i + 1).join("/")}`);

  ["mfaToken"].forEach((name) => {
    paths.forEach((path) => deleteCookie(name, path));
    deleteCookie(name, "/"); // Default path
  });
  removeMfaStatus();
};
