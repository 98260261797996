type CheckPermissionOptions = {
  id?: string;
};

type CustomPermission = {
  id: string;
  name: string;
};

type PermissionType = "FULL_ACCESS" | "READ_ONLY" | "NO_ACCESS" | "CUSTOM";

type PermissionEntry = {
  type: PermissionType;
  view: CustomPermission[] | null;
  edit: CustomPermission[] | null;
  hasCreateAccess: boolean | null;
};

type UserPermissions = {
  [resourceName: string]: PermissionEntry;
};

function hasAccess(
  permissions: CustomPermission[] | null,
  id?: string,
): boolean {
  if (!permissions) return false;
  return id ? permissions.some((p) => p.id === id) : permissions.length > 0;
}

const customPermissionChecks = {
  // Checks if user has edit permission for a specific ID
  edit: (resource: PermissionEntry, id?: string) =>
    hasAccess(resource.edit, id),

  // Checks if user has either edit OR view permission
  view: (resource: PermissionEntry, id?: string) =>
    hasAccess(resource.edit, id) || hasAccess(resource.view, id),

  // Checks if user has either create access OR edit permission
  create: (resource: PermissionEntry) => Boolean(resource.hasCreateAccess),

  // Checks if user has view OR edit permission OR create access
  viewOrCreate: (resource: PermissionEntry, id?: string) =>
    hasAccess(resource.view, id) ||
    hasAccess(resource.edit, id) ||
    Boolean(resource.hasCreateAccess),
};

// This object contains the logic for each permission type
const permissionTypeChecks = {
  FULL_ACCESS: () => true,
  READ_ONLY: (operation: string) =>
    operation === "view" || operation === "viewOrCreate",
  NO_ACCESS: () => false,
  CUSTOM: (operation: string, resource: PermissionEntry, id?: string) => {
    const check =
      customPermissionChecks[operation as keyof typeof customPermissionChecks];
    return check ? check(resource, id) : false;
  },
};

export default function checkPermissionPure(
  permissions: UserPermissions,
  resourceName: string,
  operation: "view" | "edit" | "create" | "viewOrCreate",
  options: CheckPermissionOptions = {},
): boolean {
  // Get the resource from permissions object
  const resource = permissions[resourceName];
  if (!resource) return false;

  // Get the appropriate permission check function based on resource type
  const check = permissionTypeChecks[resource.type];
  const returnVal = check(operation, resource, options.id);
  return returnVal;
}
