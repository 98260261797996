import Typography from "@/uiCore/typography";
import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const StyledTypography = styled(Typography.P2)`
  width: 290px;
`;

export const MarginDiv = styled.div`
  margin: 16px 0;
`;
