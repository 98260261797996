import { logout } from "../../helpers/authentication";
import { getAccessTokenFromCookie } from "../../helpers/cookieStorage";

export const postFetcher = async (url: string, data: any): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: JSON.stringify(data),
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};

export const postFileFetcher = async (
  url: string,
  data: any | null,
): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: data,
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const getFetcher = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};

export const getFetcherGoogle = async (
  url: string,
  code: any,
): Promise<any> => {
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${code}`,
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};

export const getFetcherWithCreds = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};

export const deleteFetcher = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};

export const putFetcher = async (url: string, data: any): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: JSON.stringify(data),
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};
