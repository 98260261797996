import { formatDistanceToNow, parseISO } from "date-fns";

export default function formatDate(timestamp: any) {
  if (!timestamp) return "";
  const date = parseISO(timestamp);
  return formatDistanceToNow(date, { addSuffix: true });
}

export const convertTimeToHours = (timeStr: string): number => {
  const timeUnits: any = {
    d: 24, // 1 day = 24 hours
    h: 1, // 1 hour = 1 hour
    m: 1 / 60, // 1 minute = 1/60 hour
    s: 1 / 3600, // 1 second = 1/3600 hour
  };

  return timeStr
    .split(" ") // Split the string by spaces
    .reduce((total, timePart) => {
      const unit = timePart.slice(-1); // Last character (d, h, m, s)
      const value = parseInt(timePart.slice(0, -1), 10); // Numeric part
      return total + (timeUnits[unit] || 0) * value;
    }, 0); // Start from 0 and accumulate
};
