/* eslint-disable complexity */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { emitter } from "@/utils/emitter";
import { ROUTES } from "../../constants/routes";
import AntButton from "../../uiCore/button";
import { emitterTypes } from "@/utils/types";
import styles from "./index.module.css";
import { useRouter } from "next/router";
import { ButtonSizes, ButtonVariant } from "@/uiCore/button/helper";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@/uiCore/typography";
import SyncStatusBadge from "../ingestion/sourceListing/syncStatusBadge";
import { Flex } from "@/styles/styled/styled";
import theme from "@/styles/theme";
import formatDate from "@/helpers/formatDate";
import NextImage from "@/uiCore/image";
import { commonIcons } from "@/constants/icons/common";
import { setSelectedJob } from "@/redux/jobs";
import { Button, Dropdown } from "antd";
import { IDEIcons } from "@/constants/icons/ide";
import { JobsIcons } from "@/constants/icons/jobs";
import { ImportButtonWrapper, NextImageWrapper } from "./styled";
import { teamsIcons } from "@/constants/icons/teams";
import Protect from "../permission/Protect";
import { Resource } from "../permission/types";
import {
  SETTINGS_ALERTS,
  SETTINGS_APP_CONNECTIONS,
} from "@/constants/permissions";

type PrimaryButtonProps = {
  path?: string;
};

export const PrimaryButton = ({ path }: PrimaryButtonProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [authCode, setAuthCode] = useState<any>(""); //eslint-disable-line
  const [secondaryLoader, setSecondaryLoader] = useState<boolean>(false);
  const [showEditButon, setShowEditButton] = useState<boolean>(false);
  const connectorLength = useSelector(
    (state: { injestion: any }) => state?.injestion?.connectorLength,
  ).connectorLength;
  const isSchemaChanged = useSelector(
    (state: { injestion: any }) => state?.injestion?.schemaChanged,
  ).schemaChanged;
  const connectorDetails = useSelector(
    (state: { injestion: any }) => state?.injestion?.connectorDetails,
  ).connectorDetails;
  // const apiKeyLength = useSelector(
  //   (state: { apiKey: any }) => state?.apiKey?.apiKeyLength,
  // ).apiKeyLength;
  const isSavingChanges = useSelector(
    (state: { injestion: any }) => state?.injestion?.isSavingChanges,
  ).isSavingChanges;

  const selectedJob = useSelector(
    (state: { jobs: any }) => state?.jobs?.selectedJob,
  )?.selectedJob;

  const isEditJobMode = useSelector(
    (state: { jobs: any }) => state?.jobs?.editMode,
  )?.editMode;

  const dataAppsLength = useSelector(
    (state: any) => state?.IDE?.dataAppList,
  )?.dataAppList;

  const urlParams = new URLSearchParams(window.location.search);
  const jobEditById = urlParams.get("id");

  const jobsRunning = useSelector(
    (state: { jobs: any }) => state?.jobs.jobsRunning,
  )?.jobsRunning;

  const { workspace } =
    useSelector((state: { user: any }) => state.user.companyDetails)
      ?.companyDetails || {};

  const isDownloading = useSelector((state: { common: any }) => state?.common)
    ?.isDownloading?.isDownloading;
  const isDataSourcesAvailable = useSelector(
    (state: { common: any }) => state?.common,
  ).isDataSourcesAvailable;

  const jobsLength = useSelector(
    (state: { jobs: any }) => state?.jobs.jobLists,
  )?.jobLists;

  const tabVal = useSelector((state: { teams: any }) => state?.teams)?.tab?.tab;

  const validate = () => {
    const fields = document.querySelectorAll(".fields-container");
    const arrayFields: any = [];
    fields.forEach((field) => {
      const required = field.getAttribute("required");
      if (required !== null) {
        const value =
          field?.querySelector(".ant-select-selection-item")?.textContent ??
          field?.querySelector("input")?.value;
        if (
          value !== undefined &&
          value === "" &&
          field.getAttribute("id") !== "gbq_service_account_key"
        ) {
          arrayFields.push({
            id: field.getAttribute("id"),
            value: "This field is required",
          });
        }
      }
    });
    emitter.emit(emitterTypes.SET_FIELD_ERROR, arrayFields);
  };

  const handleError = () => {
    setLoader(false);
  };

  const handleEditButton = (show: boolean) => {
    setShowEditButton(show);
  };

  const handleTeamsLoader = (show: boolean) => {
    setLoader(show);
  };

  useEffect(() => {
    emitter.on(emitterTypes.SET_ERROR, handleError);
    emitter.on(emitterTypes.SHOW_EDIT_BUTTON, handleEditButton);
    emitter.on(emitterTypes.SET_TEAMS_LOADER, handleTeamsLoader);
    emitter.on(emitterTypes.SET_AUTH_CODE, setAuthCode);
    return () => {
      emitter.off(emitterTypes.SET_ERROR, handleError);
      emitter.off(emitterTypes.SHOW_EDIT_BUTTON, handleEditButton);
      emitter.off(emitterTypes.SET_TEAMS_LOADER, handleTeamsLoader);
    };
  }, []);

  useEffect(() => {
    setLoader(false);
  }, [router.asPath]);

  useEffect(() => {
    setLoader(isSavingChanges);
  }, [isSavingChanges]);

  useEffect(() => {
    setSecondaryLoader(jobsRunning);
  }, [jobsRunning]);

  const handleButtonClick = (type: any) => {
    if (
      [
        emitterTypes.NEW_CONNECTION,
        emitterTypes.EDIT_CONNECTION,
        emitterTypes.EDIT_CREDENTIAL,
        emitterTypes.SAVE_CHANGES,
        emitterTypes.CREATE_API_KEY,
        emitterTypes.CANCEL,
        emitterTypes.OPEN_SIDE_DRAWER,
        emitterTypes.OPEN_ROLES_DRAWER,
        emitterTypes.ADD_CONNECTOR,
        emitterTypes.SAVE_JOB,
        emitterTypes.NEW_JOB,
        emitterTypes.RUN_NOW,
        emitterTypes.RERUN_FAILED,
        emitterTypes.EDIT_PROFILE,
        emitterTypes.CHANNEL_INTEGRATION,
        emitterTypes.NEW_ALERT,
        emitterTypes.ENABLE_DATA_APP_PREVIEW_MODE,
        emitterTypes.DISABLE_DATA_APP_PREVIEW_MODE,
        // emitterTypes.UPLOAD_CSV,
        emitterTypes.INVITE_FROM_WAREHOUSE,
      ].includes(type)
    ) {
      emitter.emit(type);
    } else if (
      [
        emitterTypes.CREATE_CONNECTION,
        emitterTypes.CREATE_CREDENTIAL,
        emitterTypes.UPDATE_CONNECTION,
        emitterTypes.UPDATE_CREDENTIAL,
      ].includes(type)
    ) {
      setLoader(true);
      validate();
      emitter.emit(type);
    } else if (
      [
        emitterTypes.DOWNLOAD_ARCHITECTURE_VIEW,
        emitterTypes.REFRESH_USERS,
      ].includes(type)
    ) {
      if (type !== emitterTypes.DOWNLOAD_ARCHITECTURE_VIEW) {
        setLoader(true);
      }
      setTimeout(() => {
        emitter.emit(type);
      }, 100);
      if (type !== emitterTypes.DOWNLOAD_ARCHITECTURE_VIEW) {
        setTimeout(() => {
          setLoader(false);
        }, 500);
      }
    } else if ([emitterTypes.EDIT_JOB].includes(type)) {
      dispatch(setSelectedJob({ editMode: true }));
    }
  };

  const renderButton = (
    variant: string,
    size: string,
    icon?: string,
    text?: string,
    type?: string,
    role?: string,
    isSecondaryButton?: boolean,
    renderType?: string,
  ) => {
    const resourceValue = role?.split(":")[0] as Resource;
    const resourceId = role?.split(":")[1];

    if (renderType === "dropdown") {
      const renderComponent = () => {
        return (
          <div className={styles["run-button-container"]}>
            <Button
              className={styles["run-button"]}
              onClick={() => handleButtonClick(type)}
            >
              <NextImageWrapper
                src={icon ? commonIcons[icon] : IDEIcons.run}
                height={12}
                width={12}
                alt="run"
              />
              {text}
            </Button>
            <Dropdown
              overlayClassName={styles["overlay-run-btn"]}
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Re-run from failed",
                    onClick: () => {
                      if (!selectedJob?.isRerunAllowed) return;
                      handleButtonClick(emitterTypes.RERUN_FAILED);
                    },
                    icon: (
                      <NextImageWrapper
                        style={{
                          opacity: !selectedJob?.isRerunAllowed ? 0.5 : 1,
                        }}
                        src={JobsIcons.reRun}
                        alt="download"
                      />
                    ),
                    disabled: !selectedJob?.isRerunAllowed,
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Button className={styles["dropdown-button"]}>
                <NextImage src={JobsIcons.activeCaretDown} alt="down" />
              </Button>
            </Dropdown>
          </div>
        );
      };

      return role ? (
        resourceId ? (
          <Protect
            resource={resourceValue}
            action="edit"
            options={{ id: resourceId }}
          >
            {renderComponent()}
          </Protect>
        ) : (
          <Protect resource={resourceValue} action="create">
            {renderComponent()}
          </Protect>
        )
      ) : (
        renderComponent()
      );
    }

    if (renderType === "import") {
      const renderComponent = () => {
        return (
          <Dropdown
            placement="bottomRight"
            menu={{
              items: [
                {
                  key: "1",
                  label: "Invite from warehouse..",
                  onClick: () => {
                    handleButtonClick(emitterTypes.INVITE_FROM_WAREHOUSE);
                  },
                  icon: (
                    <NextImageWrapper
                      src={teamsIcons.warehouse}
                      alt="download"
                    />
                  ),
                },
              ],
            }}
            trigger={["click"]}
          >
            <ImportButtonWrapper gap={8}>
              <NextImageWrapper
                src={icon ? commonIcons[icon] : ""}
                alt="down"
              />
              <Typography.P2 weight={theme.fontWeightSemibold}>
                {text}
              </Typography.P2>
              <NextImageWrapper src={IDEIcons.caretDown} alt="down" />
            </ImportButtonWrapper>
          </Dropdown>
        );
      };

      return role ? (
        resourceId ? (
          <Protect
            resource={resourceValue}
            action="edit"
            options={{ id: resourceId }}
          >
            {renderComponent()}
          </Protect>
        ) : (
          <Protect resource={resourceValue} action="create">
            {renderComponent()}
          </Protect>
        )
      ) : (
        renderComponent()
      );
    }

    const renderComponent = () => {
      return (
        <AntButton
          variant={variant as ButtonVariant}
          size={size as ButtonSizes}
          leftIcon={icon ? commonIcons[icon] : undefined}
          loading={
            isSecondaryButton
              ? secondaryLoader
              : (variant === "primary" && loader) || isDownloading
          }
          inactive={
            isSecondaryButton
              ? secondaryLoader
              : variant === "tertiary" && loader
          }
          handleClick={() => handleButtonClick(type)}
        >
          {text ? (
            text
          ) : (
            <NextImage
              src={commonIcons.edit}
              alt="edit"
              width={14}
              height={14}
            />
          )}
        </AntButton>
      );
    };

    return role ? (
      resourceId ? (
        <Protect
          resource={resourceValue}
          action="edit"
          options={{ id: resourceId }}
        >
          {renderComponent()}
        </Protect>
      ) : (
        <Protect resource={resourceValue} action="create">
          {renderComponent()}
        </Protect>
      )
    ) : (
      renderComponent()
    );
  };

  const routeButtonMap = {
    [ROUTES.Ingestion.path]: () =>
      connectorLength > 0 &&
      renderButton(
        "primary",
        "sm",
        "plus",
        "Add Connector",
        emitterTypes.ADD_CONNECTOR,
        "ingestion",
      ),
    [ROUTES.connector.path]: () =>
      isSchemaChanged ? (
        <Flex gap={10}>
          {renderButton("tertiary", "sm", "", "Cancel", emitterTypes.CANCEL)}
          {renderButton(
            "primary",
            "sm",
            "save",
            "Save Changes",
            emitterTypes.SAVE_CHANGES,
            "ingestion",
          )}
        </Flex>
      ) : (
        connectorDetails?.syncStatus && (
          <Flex gap={16}>
            <SyncStatusBadge status={connectorDetails?.syncStatus} />
            {connectorDetails.lastSync && (
              <Typography.P3 color={theme.textSecondary}>
                Last sync : {formatDate(connectorDetails.lastSync)}
              </Typography.P3>
            )}
          </Flex>
        )
      ),
    [ROUTES.Connections.path]: () =>
      showEditButon &&
      renderButton(
        "primary",
        "sm",
        "plugWhite",
        "New Connection",
        emitterTypes.NEW_CONNECTION,
        `settings:${SETTINGS_APP_CONNECTIONS}`,
      ),
    [ROUTES.profile.path]: () =>
      renderButton("primary", "sm", "edit", "Edit", emitterTypes.EDIT_PROFILE),
    [ROUTES.NewConnection.path]: () =>
      renderButton(
        "primary",
        "sm",
        undefined,
        "Connect",
        emitterTypes.CREATE_CONNECTION,
        `settings:${SETTINGS_APP_CONNECTIONS}`,
      ),
    [ROUTES.DuplicateConnection.path]: () =>
      renderButton(
        "primary",
        "sm",
        undefined,
        "Connect",
        emitterTypes.CREATE_CONNECTION,
      ),
    [ROUTES.PreviewConnection.path]: () =>
      renderButton(
        "primary",
        "sm",
        "edit",
        "Edit",
        emitterTypes.EDIT_CONNECTION,
        `settings:${SETTINGS_APP_CONNECTIONS}`,
      ),
    [ROUTES.Credentials.path]: () =>
      showEditButon &&
      renderButton(
        "primary",
        "sm",
        "edit",
        "Edit",
        emitterTypes.EDIT_CREDENTIAL,
      ),
    [ROUTES.AddCredentials.path]: () =>
      workspace?.warehouse === "bigquery"
        ? authCode &&
          renderButton(
            "primary",
            "sm",
            undefined,
            "Create credential",
            emitterTypes.CREATE_CREDENTIAL,
          )
        : renderButton(
            "primary",
            "sm",
            undefined,
            "Create credential",
            emitterTypes.CREATE_CREDENTIAL,
          ),
    [ROUTES.EditCredentials.path]: () =>
      renderButton(
        "primary",
        "sm",
        "save",
        "Save Changes",
        emitterTypes.UPDATE_CREDENTIAL,
      ),
    [ROUTES.EditConnection.path]: () => (
      <div className={styles["nav-button-edit-connection"]}>
        {renderButton(
          "primary",
          "sm",
          "checkWhite",
          "Save Changes",
          emitterTypes.UPDATE_CONNECTION,
          `settings:${SETTINGS_APP_CONNECTIONS}`,
        )}
      </div>
    ),

    [ROUTES.Team.path]: () =>
      tabVal === "1" ? (
        <Flex gap={12}>
          {renderButton(
            "tertiary",
            "sm",
            "import",
            "Import users",
            "",
            "teams",
            true,
            "import",
          )}
          {renderButton(
            "primary",
            "sm",
            "plus",
            "Add new user",
            emitterTypes.OPEN_SIDE_DRAWER,
            "teams",
          )}
        </Flex>
      ) : (
        tabVal === "2" &&
        renderButton(
          "primary",
          "sm",
          "plus",
          "Add a role",
          emitterTypes.OPEN_ROLES_DRAWER,
          "teams",
        )
      ),

    [ROUTES.Architecture.path]: () =>
      !isDataSourcesAvailable &&
      renderButton(
        "tertiary",
        "sm",
        "download",
        isDownloading ? " Downloading" : "Download",
        emitterTypes.DOWNLOAD_ARCHITECTURE_VIEW,
      ),
    [ROUTES.Jobs.path]: () =>
      jobsLength?.length
        ? renderButton(
            "primary",
            "sm",
            "plus",
            "New Pipeline",
            emitterTypes.NEW_JOB,
            "jobs",
          )
        : "",
    [ROUTES.AddJobsData.path]: () => (
      <Flex gap={10}>
        {jobEditById &&
          !isEditJobMode &&
          renderButton(
            "secondary",
            "sm",
            "playPrimary",
            "Run Now",
            emitterTypes.RUN_NOW,
            "jobs",
            true,
            "dropdown",
          )}
        {isEditJobMode
          ? renderButton(
              "primary",
              "sm",
              "save",
              "Save Job",
              emitterTypes.SAVE_JOB,
              "jobs",
            )
          : renderButton(
              "primary",
              "sm",
              "edit",
              "Edit Job",
              emitterTypes.EDIT_JOB,
              "jobs",
            )}
      </Flex>
    ),
    [ROUTES.Alerts.path]: () => (
      <Flex gap={10}>
        {renderButton(
          "tertiary",
          "sm",
          "wrench",
          "Integrations",
          emitterTypes.CHANNEL_INTEGRATION,
          `settings:${SETTINGS_ALERTS}`,
        )}
        {renderButton(
          "primary",
          "sm",
          "plus",
          "New Alert",
          emitterTypes.NEW_ALERT,
          `settings:${SETTINGS_ALERTS}`,
        )}
      </Flex>
    ),
    [ROUTES.DataApps.path]: () =>
      dataAppsLength.length
        ? renderButton(
            "tertiary",
            "sm",
            "eye",
            "Preview mode",
            emitterTypes.ENABLE_DATA_APP_PREVIEW_MODE,
          )
        : "",
    [ROUTES.DataAppsPreview.path]: () =>
      renderButton(
        "secondary_destructive",
        "sm",
        "logout",
        "Exit Preview mode",
        emitterTypes.DISABLE_DATA_APP_PREVIEW_MODE,
      ),
    [ROUTES.InviteFromWarehouse.path]: () =>
      renderButton(
        "primary",
        "sm",
        "arrowsClockWiseWhite",
        "Refresh users",
        emitterTypes.REFRESH_USERS,
      ),
  };

  return routeButtonMap[path as keyof typeof routeButtonMap]
    ? routeButtonMap[path as keyof typeof routeButtonMap]()
    : null;
};
