import theme from "@/styles/theme";
import Typography from "@/uiCore/typography";
import React, { useEffect, useState } from "react";
import {
  ContentWrapper,
  CopyIcon,
  Description,
  HeaderWrapper,
  ScriptWrapper,
} from "./styles";
import { Flex } from "@/styles/styled/styled";
import FivexLoader from "@/components/loader/FivexLoader";
import NextImage from "@/uiCore/image";
import { commonIcons } from "@/constants/icons/common";
import { Collapse } from "antd";
import styles from "./index.module.css";

const Sidebar: React.FC<{ sidebar: object }> = ({ sidebar }) => {
  const { importSetupInstructions }: { importSetupInstructions?: any } =
    sidebar || {};
  const [isCopied, setIsCopied] = useState(false);
  const [setupSteps, setSetupSteps] = useState([]);
  const handleCopy = () => {
    const prerequisitesText = document.getElementsByClassName("language-sql");
    if (prerequisitesText) {
      navigator.clipboard.writeText(prerequisitesText[0].textContent || "");
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (importSetupInstructions?.length) {
      const steps = importSetupInstructions
        ?.map((item: any, idx: any) => {
          if (item.title !== "Script") {
            return {
              key: idx,
              label: (
                <Typography.P1 weight={theme.fontWeightSemibold}>
                  {item.title}
                </Typography.P1>
              ),
              children: JSON.parse(item?.description)?.map(
                (descriptionItem: string) => {
                  return (
                    <Description
                      color={theme.neutral500}
                      key={descriptionItem}
                      dangerouslySetInnerHTML={{
                        __html: descriptionItem,
                      }}
                    />
                  );
                },
              ),
            };
          }
        })
        ?.filter(Boolean); // Filter out undefined values (for "Script" items)
      setSetupSteps(steps);
    }
  }, [importSetupInstructions]);

  return !importSetupInstructions?.length ? (
    <div>
      <Flex height={"100vh"}>
        <FivexLoader />
      </Flex>
    </div>
  ) : (
    importSetupInstructions && (
      <div>
        <HeaderWrapper>
          <Typography.H3 weight={theme.fontWeightSemibold}>
            Setup Guide
          </Typography.H3>
        </HeaderWrapper>
        <ContentWrapper>
          <Flex
            padding="0 0 32px 0"
            justifyContent="flex-start"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Collapse
              className={styles["collapse-custom"]}
              defaultActiveKey={["0"]}
              ghost
              items={setupSteps}
            />
          </Flex>
          {importSetupInstructions?.map((item: any, index: number) => (
            <>
              {item.title === "Script" && (
                <Flex
                  key={index}
                  padding="0 0 32px 0"
                  justifyContent="flex-start"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <ScriptWrapper>
                    <Typography.P3>
                      <pre id="prerequisites" className="overflow-y-hidden">
                        <code
                          className="language-sql"
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </pre>
                    </Typography.P3>
                    <CopyIcon onClick={() => handleCopy()}>
                      <NextImage
                        src={isCopied ? commonIcons.success : commonIcons.copy}
                        alt="copy"
                      />
                    </CopyIcon>
                  </ScriptWrapper>
                </Flex>
              )}
            </>
          ))}
        </ContentWrapper>
      </div>
    )
  );
};

export default Sidebar;
