import { createSlice } from "@reduxjs/toolkit";

export const securityStore = createSlice({
  name: "security",
  initialState: {
    mfaDetails: null,
    authenticationType: "qrCode",
  },
  reducers: {
    setMFADetails: (state, action) => {
      if ("mfaDetails" in action.payload) {
        state.mfaDetails = action.payload;
      }
    },
    setAuthenticationType: (state, action) => {
      if ("authenticationType" in action.payload) {
        state.authenticationType = action.payload;
      }
    },
  },
});

export const { setMFADetails, setAuthenticationType } = securityStore.actions;

export default securityStore.reducer;
