import styled from "styled-components";

export const getShortendText = (text = "", number = 15) => {
  const arr = text?.split("");
  const extraExtension = arr?.length > number && "...";
  arr?.splice(number);
  return arr?.join("") + (extraExtension || "");
};

export const highlightText = (text = "", highlight = "") => {
  const Mark = styled("mark")(() => ({
    background: "#cde4ff",
  }));

  if (highlight && text) {
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text?.split(regex);
    return parts.map((part, i) =>
      regex.test(part) ? <Mark key={i}>{part}</Mark> : part,
    );
  }
  return text;
};
