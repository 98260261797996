import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import userReducer from "./userSlice";
import injestionReducer from "./injestion";
import connectionReducer from "./connectionSlice";
import apiKeyReducer from "./apiKey";
import commonReducer from "./common";
import ideReducer from "./IDESlice";
import teamsReducer from "./teams";
import jobsStore from "./jobs";
import trialReducer from "./trial";
import dbtDocsStore from "./dbtDocs";
import utilizationReducer from "./utilization";
import rolesReducer from "./roles";
import securityStore from "./security";

const makeStore = () =>
  configureStore({
    reducer: {
      user: userReducer,
      injestion: injestionReducer,
      connection: connectionReducer,
      apiKey: apiKeyReducer,
      common: commonReducer,
      IDE: ideReducer,
      teams: teamsReducer,
      jobs: jobsStore,
      trial: trialReducer,
      dbtDocs: dbtDocsStore,
      utilization: utilizationReducer,
      roles: rolesReducer,
      security: securityStore,
    },
  });

export const wrapper = createWrapper(makeStore);
