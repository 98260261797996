export const NavigationsTop = [
  {
    name: "Ingestion",
    icon: "ingestion",
    redirectTo: "/ingestion",
    enabled: true,
    id: "ingestion",
  },
  {
    name: "IDE",
    icon: "ide",
    redirectTo: "/ide",
    enabled: true,
    id: "ide",
  },
  {
    name: "Jobs",
    icon: "jobs",
    redirectTo: "/jobs",
    enabled: true,
    id: "jobs",
  },
  { name: "BI", icon: "bi", redirectTo: "/bi", enabled: true, id: "bi" },
];

export const NavigationsMiddle = [
  {
    name: "Architecture",
    icon: "architecture",
    redirectTo: "/architecture",
    enabled: true,
    // id: "architecture",
  },
  {
    name: "Utilization",
    icon: "utilization",
    redirectTo: "/utilization",
    enabled: true,
    id: "utilization",
  },
  {
    name: "Team",
    icon: "team",
    redirectTo: "/team",
    enabled: true,
    id: "teams",
  },
];

export const NavigationsBottom = [
  {
    name: "48 hr Jumpstart",
    icon: "jumpstart",
    redirectTo: "/jumpstart",
    enabled: true,
  },
  {
    name: "Settings",
    icon: "settings",
    redirectTo: "/settings/general",
    enabled: true,
  },
  {
    name: "Help",
    icon: "help",
    redirectTo: "/help",
    enabled: true,
  },
];
