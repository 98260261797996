export const utilizationIcons: { [key: string]: string } = {
  utilizationTablePlaceholder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/  utilization-table-placeholder.svg`,
  greenDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/green-down.svg`,
  redUp: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/red-up.svg`,
  noDataChart: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/NoDataChart.svg`,
  noDataPlaceholder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/no-data-placeholder-2.svg`,
  projectionForecastGIF: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/projectionForecastGIF.gif`,
  noDataTable: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/no-data-table.svg`,
  sparkleIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/Sparkle.svg`,
  jobs020: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/State020.svg`,
  jobs2040: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/State2040.svg`,
  jobs4060: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/State4060.svg`,
  jobs6080: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/State6080.svg`,
  jobs80100: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/State80100.svg`,
  jobs0: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/State0.svg`,
  arrowUpRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/ArrowUpRight.svg`,
  timeIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/ClockCountdown.svg`,
  ingestionIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/ingestion.svg`,
  circleDashed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}utilization/CircleDashed.svg`,
};
