import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    name: null,
    code: null,
    workspaceName: "",
    onboardingData: {},
    signupMessage: "",
    loginMessage: "",
    navbar: {
      navbar: true,
    },
    navbarHovered: false,
    isDirectedFromWarehouse: false,
    companyDetails: null,
    companyName: "",
    addUser: false,
    userLength: 0,
    isRedirectedFromVerifyEmail: false,
    userName: "",
    warehouseType: "",
    navbarExpandOrCollapseComplete: {
      navbarExpandOrCollapseComplete: true,
    },
    editUser: false,
    roles: [],
    inviteUser: false,
    associateUser: false,
    externalUsers: [],
    tab: "1",
    rolesTooltipData: null,
    loadingBi: false,
    showDocsOnNavbar: {
      showDocsOnNavbar: false,
    },
    promoCode: "",
    storeChannelData: null,
    showDataAppsOnNavbar: {
      showDataAppsOnNavbar: [],
    },
    permissions: [],
  },
  reducers: {
    setUserInfo: (state, action) => {
      if ("name" in action.payload) {
        state.name = action.payload;
      }
      if ("code" in action.payload) {
        state.code = action.payload;
      }
      if ("workspaceName" in action.payload) {
        state.workspaceName = action.payload;
      }
      if ("onboardingData" in action.payload) {
        state.onboardingData = action.payload;
      }
      if ("isDirectedFromWarehouse" in action.payload) {
        state.isDirectedFromWarehouse = action.payload;
      }
      if ("isRedirectedFromVerifyEmail" in action.payload) {
        state.isRedirectedFromVerifyEmail = action.payload;
      }
      if ("userName" in action.payload) {
        state.code = action.payload;
      }
      if ("warehouseType" in action.payload) {
        state.warehouseType = action.payload;
      }
      if ("promoCode" in action.payload) {
        state.promoCode = action.payload;
      }
    },
    clearUserInfo: (state) => {
      state.name = null;
      state.code = null;
    },
    setUserAccessMessage: (state, action) => {
      if ("loginMessage" in action.payload) {
        state.loginMessage = action.payload;
      }
      if ("signupMessage" in action.payload) {
        state.signupMessage = action.payload;
      }
    },
    setNavigation: (state, action) => {
      if ("navbar" in action.payload) {
        state.navbar = action.payload;
      }
      if ("navbarHovered" in action.payload) {
        state.navbarHovered = action.payload;
      }
    },
    setNavigationExpandOrCollapseComplete: (state, action) => {
      if ("navbarExpandOrCollapseComplete" in action.payload) {
        state.navbarExpandOrCollapseComplete = action.payload;
      }
    },
    setCompanyDetails: (state, action) => {
      if ("companyDetails" in action.payload) {
        state.companyDetails = action.payload;
      }
    },
    setRolesTooltipData: (state, action) => {
      if ("rolesTooltipData" in action.payload) {
        state.rolesTooltipData = action.payload;
      }
    },
    setLoadingBi: (state, action) => {
      if ("loadingBi" in action.payload) {
        state.loadingBi = action.payload;
      }
    },
    setDbtDocsOnNavbar: (state, action) => {
      if ("showDocsOnNavbar" in action.payload) {
        state.showDocsOnNavbar = action.payload;
      }
    },
    setStoreChannelData: (state, action) => {
      if ("storeChannelData" in action.payload) {
        state.storeChannelData = action.payload;
      }
    },
    setShowDataAppsOnNavbar: (state, action) => {
      if ("showDataAppsOnNavbar" in action.payload) {
        state.showDataAppsOnNavbar = action.payload;
      }
    },
    setPermissions: (state, action) => ({
      ...state,
      permissions: action.payload,
    }),
  },
});

export const {
  setUserInfo,
  setUserAccessMessage,
  setNavigation,
  setCompanyDetails,
  setNavigationExpandOrCollapseComplete,
  setRolesTooltipData,
  setLoadingBi,
  setDbtDocsOnNavbar,
  setStoreChannelData,
  setShowDataAppsOnNavbar,
  setPermissions,
} = userSlice.actions;

export default userSlice.reducer;
