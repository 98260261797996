import React from "react";
import Typography from "../../../uiCore/typography";
import theme from "../../../styles/theme";
import { NaviagationsWrapper } from "./styled";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useIntercom } from "react-use-intercom";
import NextImage from "@/uiCore/image";
import { navigationIcons } from "@/constants/icons/navigations";
import { emitterTypes } from "@/utils/types";
import { emitter } from "@/utils/emitter";
import { settingsMenu } from "@/constants/settings";
import { getShortendText } from "@/helpers/ellipsis";
import { setDataAppPreviewId } from "@/redux/IDESlice";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import checkPermissionPure from "@/utils/checkPermissionPure";

type NavigationItem = {
  name: string;
  path: string;
  icon: string;
  redirectTo: string;
  enabled: boolean;
  id: string;
  type?: string;
};

type NavigationsProps = {
  navigationData?: Array<NavigationItem>;
};

const Navigations: React.FC<NavigationsProps> = ({ navigationData = [] }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { boot } = useIntercom();
  const navbarStatus = useSelector(
    (state: { user: any }) => state.user.navbar,
  ).navbar;
  const currentPathname = window.location.pathname;
  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  )?.companyDetails;

  const permissions =
    useSelector((state: { user: any }) => state.user.permissions) || {};
  const [navbarHovered, setNavbarHovered] = React.useState("");

  const updateCall = () => {
    boot({
      name: companyData.name,
    });
  };

  const getNavIcon = (icon: string, id: string) => {
    if (id === "jumpstart" && navbarHovered === "jumpstart") {
      return navigationIcons.jumpstartWhiteFill;
    }
    return navigationIcons[icon as keyof typeof navigationIcons];
  };

  const getRouteMatcher = () => {
    const settingRoute = settingsMenu?.find((item) => {
      if (item?.id) {
        return checkPermissionPure(permissions, "settings", "view", {
          id: item?.id?.toUpperCase(),
        });
      }
      return true;
    });
    if (settingRoute) {
      router.push(settingRoute.path);
    } else {
      router.push("/settings/general");
    }
  };
  const triggerDataAppPreview = (id: string) => {
    dispatch(setDataAppPreviewId({ dataAppPreviewId: id }));
    router.push(`/data-apps/${id}`);
  };

  const renderNavigationItem = (navigation: NavigationItem, index: number) => {
    return (
      <NaviagationsWrapper
        onMouseEnter={() => setNavbarHovered(navigation.id)}
        onMouseLeave={() => setNavbarHovered("")}
        id={navigation.id}
        key={index}
        active={
          navigation.name === "Data Apps" || navigation.name === "Data App"
            ? currentPathname === navigation.redirectTo
            : currentPathname.startsWith(navigation.redirectTo)
        }
        isGradient={navigation.icon === "jumpstart"}
        onClick={() =>
          navigation.enabled && navigation.redirectTo === "/help"
            ? updateCall()
            : navigation.enabled && navigation.redirectTo === "/jumpstart"
              ? emitter.emit(emitterTypes.OPEN_JUMPSTART_MODAL, true)
              : navigation.name === "settings"
                ? getRouteMatcher()
                : navigation.type === "dataApp"
                  ? triggerDataAppPreview(navigation.id)
                  : router.push(navigation.redirectTo)
        }
        enabled={navigation.enabled}
      >
        {navigation.type === "dataApp" ? (
          <Tooltip
            title={!navbarStatus ? navigation.name : ""}
            placement="right"
          >
            <Typography.H3>{navigation.icon}</Typography.H3>
          </Tooltip>
        ) : (
          <NextImage
            height={20}
            width={20}
            src={getNavIcon(navigation.icon, navigation.id)}
            alt={navigation.icon}
            tooltip={!navbarStatus ? navigation.name : ""}
            tooltipPosition="right"
          />
        )}
        {navbarStatus && (
          <Typography.P2 color={theme.textWhite}>
            {getShortendText(navigation.name, 16)}
          </Typography.P2>
        )}
      </NaviagationsWrapper>
    );
  };

  return (
    <>
      {navigationData.map((navigation: NavigationItem, index: number) =>
        navigation?.id
          ? (checkPermissionPure(permissions, navigation?.id, "view") ||
              checkPermissionPure(permissions, navigation?.id, "create")) &&
            renderNavigationItem(navigation, index)
          : renderNavigationItem(navigation, index),
      )}
    </>
  );
};

export default Navigations;

{
  /* <NaviagationsWrapper
        onMouseEnter={() => setNavbarHovered(navigation.id)}
        onMouseLeave={() => setNavbarHovered("")}
        id={navigation.id}
        key={index}
        active={currentPathname.startsWith(navigation.redirectTo)}
        isGradient={navigation.icon === "jumpstart"}
        onClick={() =>
          navigation.enabled && navigation.redirectTo === "/help"
            ? updateCall()
            : navigation.enabled && navigation.redirectTo === "/jumpstart"
              ? emitter.emit(emitterTypes.OPEN_JUMPSTART_MODAL, true)
              : navigation.id === "settings"
                ? getRouteMatcher()
                : router.push(navigation.redirectTo)
        }
        enabled={navigation.enabled}
      >
        <NextImage
          height={20}
          width={20}
          src={getNavIcon(navigation.icon, navigation.id)}
          alt={navigation.icon}
          tooltip={!navbarStatus ? navigation.name : ""}
          tooltipPosition="right"
        />
        {navbarStatus && (
          <Typography.P2 color={theme.textWhite}>
            {navigation.name}
          </Typography.P2>
        )}
      </NaviagationsWrapper> */
}
