export const onboardingIcons: { [key: string]: string } = {
  amalan: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/amalan.svg`,
  author: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/anthonyMain.svg`,
  bigQuery: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/big-query.svg`,
  cupbop: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/cupbop.svg`,
  glossyCoupon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/glassy-coupon.svg`,
  georgeFerreira: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/georgeFerreiraMain.svg`,
  launchMetrics: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/launch-metrics.svg`,
  monicaVinader: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/monica-vinader.svg`,
  novo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/novo.svg`,
  setup: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/set-up.gif`,
  signUpBackground: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/signup-background.png`,
  "onboarding-background": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/onboarding-background.png`,
  snowflake: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/snowflake.svg`,
  steveHibberd: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/steveHibberdMain.svg`,
  topShelfInternational: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/top-shelf-international.svg`,
  tripScout: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/trip-scout.svg`,
  xap: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/xap.svg`,
  setupWarehouseCoupon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/setupWarehouseCoupon.svg`,
  gbq: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}onboarding/gbq.svg`,
};
