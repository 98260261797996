import { useGrowthBook } from "@growthbook/growthbook-react";
import { useEffect, useMemo, useState } from "react";

// Utility function for feature flag management
export const useFeatureManager = (attributes?: Record<string, any>) => {
  const growthbook = useGrowthBook();
  const [featuresReady, setFeaturesReady] = useState(false); // Track when flags are refreshed

  useEffect(() => {
    const updateFeatures = async () => {
      // Dynamically set attributes
      if (attributes) {
        growthbook.setAttributes(attributes);
      }
      // Fetch updated flags
      await growthbook.refreshFeatures();
      setFeaturesReady(true); // Mark flags as ready
    };

    updateFeatures();
  }, [growthbook]);

  /**
   * Evaluate a feature flag with a mandatory fallback
   * @param {string} flagKey - The key of the feature flag to evaluate
   * @param {boolean} fallback - The fallback value to return if the flag is unavailable
   * @returns {boolean} - The value of the feature flag (true/false)
   */
  const isFeatureEnabled = useMemo(() => {
    return (flagKey: string, fallback: boolean): boolean => {
      if (fallback === undefined) {
        throw new Error(`Fallback value is required for flag '${flagKey}'`);
      }
      return featuresReady ? growthbook.isOn(flagKey) : fallback;
    };
  }, [growthbook, featuresReady]);

  /**
   * Retrieve the value of a feature flag with a mandatory fallback
   * @param {string} flagKey - The key of the feature flag
   * @param {any} fallback - The fallback value to return if the flag is unavailable
   * @returns {any} - The value of the feature flag
   */
  const getFeatureValue = useMemo(() => {
    return (flagKey: string, fallback: any): any => {
      if (fallback === undefined) {
        throw new Error(`Fallback value is required for flag '${flagKey}'`);
      }
      return featuresReady
        ? growthbook.getFeatureValue(flagKey, fallback)
        : fallback;
    };
  }, [growthbook, featuresReady]);

  return {
    isFeatureEnabled,
    getFeatureValue,
  };
};
