const theme = {
  // Colors
  neutral25: "var(--neutral-25)",
  neutral30: "var(--neutral-30)",
  neutral50: "var(--neutral-50)",
  neutral100: "var(--neutral-100)",
  neutral200: "var(--neutral-200)",
  neutral300: "var(--neutral-300)",
  neutral400: "var(--neutral-400)",
  neutral500: "var(--neutral-500)",
  neutral600: "var(--neutral-600)",
  neutral700: "var(--neutral-700)",
  neutral800: "var(--neutral-800)",
  neutral900: "var(--neutral-900)",
  textDisabled: "var(--text-disabled)",
  textTertiary: "var(--text-tertiary)",
  textSecondary: "var(--text-secondary)",
  textPrimary: "var(--text-primary)",
  textBlack: "var(--text-black)",
  textWhite: "var(--text-white)",
  primary25: "var(--primary-25)",
  primary50: "var(--primary-50)",
  primary100: "var(--primary-100)",
  primary200: "var(--primary-200)",
  primary300: "var(--primary-300)",
  primary400: "var(--primary-400)",
  primary500: "var(--primary-500)",
  primary600: "var(--primary-600)",
  primary700: "var(--primary-700)",
  primary800: "var(--primary-800)",
  primary900: "var(--primary-900)",
  error25: "var(--error-25)",
  error50: "var(--error-50)",
  error75: "var(--error-75)",
  error100: "var(--error-100)",
  error200: "var(--error-200)",
  error300: "var(--error-300)",
  error400: "var(--error-400)",
  error500: "var(--error-500)",
  error600: "var(--error-600)",
  error700: "var(--error-700)",
  error800: "var(--error-800)",
  error900: "var(--error-900)",
  warning25: "var(--warning-25)",
  warning50: "var(--warning-50)",
  warning100: "var(--warning-100)",
  warning200: "var(--warning-200)",
  warning300: "var(--warning-300)",
  warning400: "var(--warning-400)",
  warning500: "var(--warning-500)",
  warning600: "var(--warning-600)",
  warning700: "var(--warning-700)",
  warning800: "var(--warning-800)",
  warning900: "var(--warning-900)",
  success25: "var(--success-25)",
  success50: "var(--success-50)",
  success100: "var(--success-100)",
  success101: "var(--success-s-100)",
  success200: "var(--success-200)",
  success300: "var(--success-300)",
  success400: "var(--success-400)",
  success500: "var(--success-500)",
  success600: "var(--success-600)",
  success700: "var(--success-700)",
  success800: "var(--success-800)",
  success900: "var(--success-900)",

  // Font Sizes
  fontH1: "var(--font-h1)",
  fontH2: "var(--font-h2)",
  fontH3: "var(--font-h3)",
  fontP1: "var(--font-p1)",
  fontP2: "var(--font-p2)",
  fontP3: "var(--font-p3)",
  fontL1: "var(--font-l1)",
  fontL2: "var(--font-l2)",

  // Font Weights
  fontWeightRegular: "var(--font-weight-regular)",
  fontWeightSemibold: "var(--font-weight-semibold)",
  fontWeightBold: "var(--font-weight-bold)",

  // Letter Spacing
  letterSpacing: "var(--letter-spacing)",

  // Button properties
  buttonSmHeight: "var(--button-sm-height)",
  buttonMdHeight: "var(--button-md-height)",
  buttonLgHeight: "var(--button-lg-height)",
  buttonSmPadding: "var(--button-sm-padding)",
  buttonMdPadding: "var(--button-md-padding)",
  buttonLgPadding: "var(--button-lg-padding)",
  buttonBorderRadius: "var(--button-border-radius)",

  // Input field properties
  inputSmHeight: "var(--input-sm-height)",
  inputMdHeight: "var(--input-md-height)",
  inputSmPadding: "var(--input-sm-padding)",
  inputMdPadding: "var(--input-md-padding)",
  inputBorderRadius: "var(--input-border-radius)",

  gradientBg: "var(--angular)",
};

export default theme;
