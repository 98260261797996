import styled from "styled-components";
import theme from "../../styles/theme";
import Image from "../image";

type ModalHeaderProps = {
  showHeaderDivider?: boolean;
  children: React.ReactNode;
};

export const ModalHeader = styled.div<ModalHeaderProps>((props) => ({
  padding: "16px 16px 8px 16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "48px",
  borderBottom: props.showHeaderDivider ? `1px solid ${theme.neutral300}` : "",
}));

export const HeaderText = styled.div(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
}));

export const ModalContent = styled.div(() => ({
  padding: "8px 16px",
}));

export const ModalFooter = styled.div(() => ({
  backgroundColor: theme.neutral50,
  borderRadius: "0 0 8px 8px",
  padding: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const FooterButtonWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
}));

export const PointerImage = styled(Image)(() => ({
  cursor: "pointer",
}));

export const DeleteConfirmation = styled.div(() => ({
  marginTop: "16px",
}));
