import React, { useEffect, useState, useRef } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";
import Navbar from "../components/navbar";
import { useDispatch, useSelector } from "react-redux";
import styles from "./mainLayout.module.css";
import { Header } from "../components/header";
import { ROUTES } from "../constants/routes";
import { useRouter } from "next/router";
import { SettingsLayout } from "./settingsLayout";
import { apiEndpoints } from "@/constants/api";
import { getFetcher, postFetcher } from "@/services/connections";
import {
  setCompanyDetails,
  setRolesTooltipData,
  setNavigationExpandOrCollapseComplete,
  setLoadingBi,
  setDbtDocsOnNavbar,
  setShowDataAppsOnNavbar,
  setPermissions,
  setNavigation,
} from "@/redux/userSlice";
import { setBIInstance } from "@/helpers/cookieStorage";
import { emitter } from "@/utils/emitter";
import { emitterTypes } from "@/utils/types";
import { setTrial } from "@/redux/trial";
import { SuccessModal } from "@/components/trial/successModal";
import Trial from "@/components/trial";
import useSWR from "swr";
import { heapIdentity } from "@/utils/helper";
import { TrialBackground } from "@/layouts/styles";
import { FivexLoader } from "@/components/loader/FivexLoader";
import { Flex } from "@/styles/styled/styled";
import { Alert } from "antd";
import useDebounce from "@/hooks/useDebounce";
import useSize from "@/hooks/useWindowSize";
import { iconPath } from "@/constants/icons";
import Image from "next/image";
import { Jumpstart } from "@/components/jumpstart";
import setDefaultLandingPageInSessionStorage from "@/helpers/setDefaultLandingPageInSessionStorage";
import { setDataApps } from "@/redux/IDESlice";

type MainLayoutProps = {
  children: React.ReactNode;
  isBackButton?: boolean;
  isSettingsLayout?: boolean;
  pageHeader?: boolean;
  headerTitle?: string;
};

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  isBackButton,
  isSettingsLayout,
  pageHeader,
  headerTitle,
}) => {
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const navbarStatus = useSelector(
    (state: { user: any }) => state.user.navbar,
  ).navbar;
  const [displayNavbar, setDisplayNavbar] = useState(false);
  const router = useRouter();
  const [size, setSize] = useState<number>(200);
  const dispatch = useDispatch();
  const { userData, role } = apiEndpoints;
  const [windowWidth] = useDebounce(useSize(), 300);
  //eslint-disable-next-line
  const [isOpend, setIsOpend] = useState(false);
  const { data, isLoading, mutate } = useSWR(userData);
  const [initialRender, setInitialRender] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const { data: roleData, mutate: roleDataMutate } = useSWR(role);
  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  );
  const rolesData = useSelector((state: { user: any }) => state.user.rolesData);
  const plans = useSelector((state: { trial: any }) => state?.trial?.plans);
  const plansFetched = useRef(false);
  const showJumpStartModalAfterMins =
    useSelector(
      (state: { user: any }) =>
        state.user.companyDetails?.companyDetails?.showJumpStartModalAfterMins,
    ) || 0;

  useEffect(() => {
    if (data) {
      dispatch(setCompanyDetails({ companyDetails: data }));
      dispatch(setPermissions(data.permissions));
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (roleData?.data) {
      dispatch(setRolesTooltipData({ rolesTooltipData: roleData.data }));
    }
  }, [roleData, isLoading]);

  const setDetails = (data: any) => {
    heapIdentity(data?.name, data?.email, data?.workspace?.name);
    mutate(data);
    const showDocsOnNavbar = data?.workspace?.settings?.showDocsInNavbar;
    dispatch(setDbtDocsOnNavbar({ showDocsOnNavbar: showDocsOnNavbar }));
    const showDataAppsOnNavbar = data?.workspace?.publishedDataApps;
    dispatch(
      setShowDataAppsOnNavbar({ showDataAppsOnNavbar: showDataAppsOnNavbar }),
    );
    dispatch(
      setDataApps({
        dataAppList: showDataAppsOnNavbar,
      }),
    );
    const defaultLandingPage = data?.workspace?.settings?.defaultLandingPage;
    const permissions = data?.permissions;
    setDisplayNavbar(true);
    setDefaultLandingPageInSessionStorage(defaultLandingPage, permissions);

    if (data?.workspace?.trialPeriod?.status === "EXPIRED") {
      emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, true);
      dispatch(
        setTrial({
          title: "Your trial period is over",
          closable: false,
          trialExpired: true,
        }),
      );
      setIsTrialExpired(true);
    }
  };

  const getUserData = async () => {
    const res = await getFetcher(apiEndpoints.userData);
    if (res?.status === "SUCCESS") {
      setDetails(res?.data);
    }
  };

  const getRolesData = async () => {
    const res = await getFetcher(role);
    roleDataMutate(res);
  };

  const getPlans = async () => {
    if (!plansFetched.current) {
      const res = await getFetcher(
        `${apiEndpoints.dccPlans}?includePlanPricing=true`,
      );
      if (res?.status === "SUCCESS") {
        dispatch(setTrial({ plans: res?.data }));
        plansFetched.current = true;
      }
    }
  };

  useEffect(() => {
    dispatch(setNavigation({ navbar: true }));
  }, []);

  useEffect(() => {
    if (!companyData) {
      getUserData();
    } else {
      setDetails(companyData?.companyDetails);
    }
  }, []);

  useEffect(() => {
    if (!rolesData) {
      getRolesData();
    }
    if (!plans) {
      getPlans();
    }
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (showJumpStartModalAfterMins) {
      interval = setTimeout(
        () => {
          setIsOpend((prev) => {
            if (!prev) {
              emitter.emit(emitterTypes.OPEN_JUMPSTART_MODAL, true);
            }
            return prev;
          });
        },
        showJumpStartModalAfterMins * 60 * 1000,
      );
    }

    return () => clearTimeout(interval);
  }, [showJumpStartModalAfterMins]);

  useEffect(() => {
    emitter.on(emitterTypes.CLOSE_JUMPSTART_MODAL, (value: boolean) => {
      setIsOpend(value);
    });
    return () => {
      emitter.off(emitterTypes.CLOSE_JUMPSTART_MODAL, () => {
        setIsOpend(false);
      });
    };
  }, []);
  let intervalId: any;

  const getBiStatus = async (intervalId: NodeJS.Timeout) => {
    dispatch(setLoadingBi({ loadingBi: true }));
    const res = await postFetcher(apiEndpoints.supersetLogin, {});
    if (res?.status === "SUCCESS") {
      if (res?.data.loginStatus === "SUCCESS") {
        clearInterval(intervalId);
        setBIInstance(res.data.loginUrl);
        dispatch(setLoadingBi({ loadingBi: false }));
      } else if (res?.data.loginStatus === "PROVISIONING_IN_PROGRESS") {
        setBIInstance("PROVISIONING_IN_PROGRESS");
      } else {
        setBIInstance("NOT_PROVISIONED");
        dispatch(setLoadingBi({ loadingBi: false }));
        clearInterval(intervalId);
      }
    } else if (res?.data?.loginStatus === "PROVISIONING_IN_PROGRESS") {
      setBIInstance("PROVISIONING_IN_PROGRESS");
    } else {
      setBIInstance("NOT_PROVISIONED");
      dispatch(setLoadingBi({ loadingBi: false }));
      clearInterval(intervalId);
    }
  };

  useEffect(() => {
    intervalId = setInterval(() => {
      getBiStatus(intervalId);
    }, 20000);

    // Call it immediately
    if (!router.asPath.includes("/bi")) {
      getBiStatus(intervalId);
    }

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (router.asPath.includes("/bi")) {
      getBiStatus(intervalId);
    }
  }, [router.asPath]);

  useEffect(() => {
    emitter.on(emitterTypes.TRIAL_UPGRADED, getUserData);
    return () => {
      emitter.off(emitterTypes.TRIAL_UPGRADED, getUserData);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1000) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }
    if (navbarStatus) {
      onMaximizeClicked();
    } else {
      onMinimizeClicked();
    }
  }, [navbarStatus]);

  const onMinimizeClicked = () => {
    const leftPaneElement = document.querySelector(".left-pane");
    const currentSize = leftPaneElement ? leftPaneElement?.clientWidth : 0;
    const update = (sizeq: number) => {
      return Promise.resolve(setSize(sizeq < 72 ? 72 : sizeq));
    };

    const done = (from: number, to: number) => {
      return from < to;
    };

    animate(currentSize, 72, -10, done, update);
  };

  const onMaximizeClicked = () => {
    const leftPaneElement = document.querySelector(".left-pane");
    const currentSize = leftPaneElement ? leftPaneElement?.clientWidth : 0;

    const update = (sizeq: number) => {
      return Promise.resolve(setSize(sizeq));
    };

    const done = (from: number, to: number) => {
      return from > to;
    };

    animate(currentSize, 200, 10, done, update);
  };

  const animate = (
    start: number,
    end: number,
    step: number,
    done: (start: number, end: number) => boolean,
    fn: (value: number) => Promise<void>,
  ) => {
    const stepFn = () => {
      if (!done(start, end)) {
        fn((start += step)).then(() => {
          window.requestAnimationFrame(stepFn);
        });
      } else {
        dispatch(
          setNavigationExpandOrCollapseComplete({
            navbarExpandOrCollapseComplete: navbarStatus,
          }),
        );
      }
    };
    stepFn();
  };

  const isHeaderPresent = Object.keys(ROUTES).some((key) => {
    //eslint-disable-next-line
    return ROUTES[key].path === router.pathname && ROUTES[key].pageHeader;
  });

  return (
    <>
      {showAlert && (
        <Alert
          message="For an optimal experience, please increase your display size."
          type="warning"
          showIcon
          icon={
            <Image
              src={iconPath.screenOptimization}
              alt="warning"
              width={24} // Adjust the size as needed
              height={24} // Adjust the size as needed
              priority // Optional: Optimize for fast loading
            />
          }
          closable
        />
      )}
      <div className={styles.splitter}>
        {isTrialExpired ? (
          <TrialBackground />
        ) : displayNavbar ? (
          <ReflexContainer orientation="vertical">
            <ReflexElement
              className="left-pane"
              size={size}
              minSize={72}
              maxSize={220}
            >
              <Navbar />
            </ReflexElement>
            <ReflexSplitter className="splitter-main-layout" />
            <ReflexElement className="right-pane">
              <div className="pane-content">
                {!isSettingsLayout && isHeaderPresent && (
                  <Header isBackButton={isBackButton} />
                )}
                {isSettingsLayout ? (
                  <SettingsLayout
                    isBackButton={isBackButton}
                    pageHeader={pageHeader}
                    headerTitle={headerTitle}
                  >
                    {children}
                  </SettingsLayout>
                ) : (
                  <div>{children}</div>
                )}
              </div>
            </ReflexElement>
          </ReflexContainer>
        ) : (
          <Flex height={"100vh"}>
            <FivexLoader />
          </Flex>
        )}
      </div>
      <Trial />
      <Jumpstart />
      <SuccessModal />
    </>
  );
};
