export const AlertsIcons: { [key: string]: string } = {
  slack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/slack.svg`,
  email: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/email.svg`,
  disconnectSlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/disconnect-slack.svg`,
  slackNoDataGif: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/slack_no_data.gif`,
  forecastDisabled: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/forecast-disabled.svg`,
  lightning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/lightning.svg`,
  linkBreak: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/link-break.svg`,
  megaPhone: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/mega_phone.svg`,
  notificationOn: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/notification_on.svg`,
  notificationOff: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}alerts/notification_off.svg`,
};
