export const navigationIcons: { [key: string]: string } = {
  alerts: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/alerts.svg`,
  alertsSmall: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/alerts-small.svg`,
  appConnection: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/appConnection.svg`,
  appConnectionActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/appConnectionActive.svg`,
  architecture: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/architecture.svg`,
  arrowCircleRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/arrow-circle-right.svg`,
  bi: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/bi.svg`,
  billing: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/billing-icon-small.svg`,
  collapse: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/collapse.svg`,
  credential: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/credential.svg`,
  credentialActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/credentialActive.svg`,
  env: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/env.svg`,
  expand: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/expand.svg`,
  githubSmall: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/github-logo.svg`,
  help: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/help.svg`,
  ide: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/ide.svg`,
  ingestion: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/ingestion.svg`,
  jobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/jobs.svg`,
  key: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/Key.svg`,
  keyActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/keyActive.svg`,
  logout: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/logout.svg`,
  profile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/profile-icon-small.svg`,
  profileActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/profileActive.svg`,
  security: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/security.svg`,
  secrets: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/secrets.svg`,
  settings: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/settingsNav.svg`,
  settingsNew: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/settingsNew.svg`,
  team: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/team.svg`,
  trial: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial.svg`,
  trial20: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial-20.svg`,
  trial2040: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial-20-40.svg`,
  trial40: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial-40.svg`,
  trialOver: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial-over.svg`,
  utilization: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/utilization.svg`,
  environmentActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/At.svg`,
  environment: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/enviornment.svg`,
  billingActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}billing-active.svg`,
  alertsActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/alerts-active.svg`,
  alertsBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/alerts-black.svg`,
  dbtDocs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/dbtDocInActive.svg`,
  dbtDocsActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/dbtDoc.svg`,
  dbtDocWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/dbtDocWhite.svg`,
  general: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/general.svg`,
  generalActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/general-active.svg`,
  trialLight20: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial-light-20.svg`,
  trialLight2040: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial-light-20-40.svg`,
  trialLight40: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/trial-light-40.svg`,
  jumpstart: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/button-jumpstart.svg`,
  jumpstartContent: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/jumpstart-content-4.svg`,
  jumpstartWhiteFill: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/lightning-white.svg`,
  jumpstartGif: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/flash.gif`,
  dataApps: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/dataApps.svg`,
  securityIconActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/security-icon-active.svg`,
  securityIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}navigations/security-icon.svg`,
};
