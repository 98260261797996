import { clearMFATokens, clearTokens } from "./cookieStorage";
import localforage from "localforage";

export const logout = () => {
  clearMFATokens();
  clearTokens();
  localforage.clear();
  localStorage.removeItem("referralCode");
  window.location.href = "/login";
};
