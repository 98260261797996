// A custom fetcher that performs a POST request
// const baseURL = "https://apinextgen-dev.5x.co";

import { logout } from "../../helpers/authentication";
import { getAccessTokenFromCookie } from "../../helpers/cookieStorage";

type user = {
  name: string;
  email?: string;
  department?: string;
  jobTitle?: string;
  country?: string;
  profileImage?: string;
  currentPassword?: string;
  newPassword?: string;
};

// A custom fetcher that performs a POST request
export const postFetcher = async (
  url: string,
  data: user | null,
): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok && response.status === 401) {
    return logout();
  }
  return response.json();
};

export const putFetcher = async (url: string, data: any): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok && response.status === 401) {
    return logout();
  }
  return response.json();
};

export const putFetcherFile = async (
  url: string,
  data: FormData,
): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: data,
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const postFetcherNoAuth = async (
  url: string,
  data: any,
): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const getFetcher = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const deleteFetcher = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};
