import React, { useState, useRef, useEffect } from "react";
import Typography from "@/uiCore/typography";
import theme from "@/styles/theme";
import { Flex } from "@/styles/styled/styled";
import {
  PlanTag,
  PlanDescription,
  Warehouselabel,
  StyledCol,
  StyledRow,
  PlanValue,
  BiContainer,
  BiTitle,
  BiSubtitle,
  AntSwitch,
  ImageWrapper,
  EmptyStateDiv,
  DiscountTag,
  BiIcon,
} from "./styled";
import { Col, Row, Tooltip } from "antd";
import AntButton from "@/uiCore/button";
import { billingIcons } from "@/constants/icons/billing";
import Image from "@/uiCore/image";
import { trialIcons } from "@/constants/icons/trial";
import { postFetcher, putFetcher } from "@/services/connections";
import { apiEndpoints } from "@/constants/api";
import CardPayment from "./cardPayment";
import { useNotification, Message } from "@/uiCore/message";
import styles from "./index.module.css";
import { commonIcons } from "@/constants/icons/common";
import StorageTable from "../storageTable";
import { useSelector } from "react-redux";

type TrialUpgradeProps = {
  data: any;
  cardCallBack: any;
  isAddCard: boolean;
  setIsAddCard: any;
  handleSuccessCallBack: any;
};

export const TrialUpgrade: React.FC<TrialUpgradeProps> = ({
  data,
  cardCallBack,
  isAddCard,
  setIsAddCard,
  handleSuccessCallBack,
}) => {
  const { workspace } =
    useSelector((state: { user: any }) => state.user.companyDetails)
      ?.companyDetails || {};
  const { showNotification, notification } = useNotification();
  const [tableData, setTableData] = useState([
    { label: "5X Credit price", value: data?.creditPrice },
    { label: "Ingestion", value: data?.ingestion?.label },
    { label: "Jobs", value: data?.jobs?.label },
  ]);

  useEffect(() => {
    if (workspace?.warehouse === "snowflake") {
      setTableData([
        ...tableData,
        {
          label: "Warehouse",
          value: `Compute :${data?.warehouse?.compute?.price} Storage :${data?.warehouse?.storage?.price}`,
        },
      ]);
    }
  }, []);

  const [includeBi, setIncludeBi] = useState(true);
  const [clientSecretStripe, setClientSecretStripe] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<any>(null);

  const handleBiChange = (val: any) => {
    setIncludeBi(val);
  };

  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  );
  const bipricingPlan =
    companyData?.companyDetails?.workspace?.biPricingPlan || "USER";

  const handleAddACard = async () => {
    setIsLoading(true);
    const response = await postFetcher(apiEndpoints.paymentMethods, {});
    setClientSecretStripe(response?.data.clientSecret);
    setTimeout(() => {
      setIsLoading(false);
      setIsAddCard(true);
      cardCallBack();
    }, 300);
  };

  const handleUpgrade = () => {
    if (formRef.current) {
      setIsLoading(true);
      formRef.current.submitForm().then((cardStatus: any) => {
        if (cardStatus?.status === "success") {
          setIsLoading(true);
          putFetcher(
            `${apiEndpoints.paymentMethods}/${cardStatus.value}`,
            null,
          ).then((res: any) => {
            if (res?.status === "SUCCESS") {
              postFetcher(apiEndpoints.dccPlanUpgrade, {
                includeBI: includeBi,
              }).then((resp: any) => {
                if (resp?.status === "SUCCESS") {
                  setIsAddCard(false);
                  handleSuccessCallBack();
                } else {
                  setIsLoading(false);
                  showNotification("error", "Failed to upgrade plan");
                }
              });
            } else {
              setIsLoading(false);
              showNotification("error", "Failed to upgrade plan");
            }
          });
        } else {
          setIsLoading(false);
          showNotification("error", cardStatus?.value);
        }
      });
    }
  };

  return (
    <>
      {notification && (
        <Message
          status={notification.status as any}
          message={notification.message}
        />
      )}
      <div>
        {isAddCard ? (
          <>
            <ImageWrapper>
              <Image
                src={trialIcons.cardIcon}
                alt="card"
                width={474}
                height={68}
              />
            </ImageWrapper>
            <CardPayment
              clientSecret={clientSecretStripe}
              setIsLoading={setIsLoading}
              ref={formRef}
            />
            <AntButton
              size="md"
              fullWidth
              variant="primary"
              handleClick={() => handleUpgrade()}
              leftIcon={isLoading ? "" : billingIcons.arrowRightWhite}
              loading={isLoading}
            >
              Upgrade
            </AntButton>
          </>
        ) : (
          <>
            <Flex justifyContent="space-between">
              <Flex padding="0 0 16px 0">
                <Typography.P1 weight={theme.fontWeightSemibold}>
                  {data?.name}
                </Typography.P1>
                <PlanTag color={true}>
                  {data?.planTags && data?.planTags[0]}
                </PlanTag>
                <Tooltip
                  placement="bottom"
                  overlayStyle={{ maxWidth: 320 }}
                  overlayClassName={styles.planTooltip}
                  title="You will be billed on the 5th of the next month, pay only as per actual usage."
                >
                  <Typography.P2>
                    <Image
                      src={commonIcons.info}
                      alt="info"
                      width={16}
                      height={16}
                      className="cursor-pointer"
                    />
                  </Typography.P2>
                </Tooltip>
              </Flex>
            </Flex>
            <div>
              {tableData?.map((item, index) => {
                if (item.label === "Warehouse") {
                  return (
                    <Row key={index}>
                      <StyledCol span={8}>
                        <Warehouselabel>{item.label}</Warehouselabel>
                      </StyledCol>
                      <Col span={16}>
                        <StyledRow>
                          <Col span={24}>
                            <Flex justifyContent="flex-start">
                              <PlanValue>
                                {`Compute: ${data?.warehouse?.compute?.price}`}
                              </PlanValue>
                              {data?.warehouse?.compute?.discountPercentage &&
                              data?.warehouse?.compute?.discountPercentage !=
                                0 ? (
                                <DiscountTag>{`${data?.warehouse?.compute?.discountPercentage}% Off`}</DiscountTag>
                              ) : null}
                            </Flex>
                          </Col>
                        </StyledRow>
                        <StyledRow>
                          <Col span={24}>
                            <Flex justifyContent="flex-start">
                              <PlanValue>
                                {`Storage: ${data?.warehouse?.storage?.price}`}
                              </PlanValue>
                              {data?.warehouse?.storage?.discountPercentage &&
                              data?.warehouse?.storage?.discountPercentage !=
                                0 ? (
                                <DiscountTag>{`${data?.warehouse?.storage?.discountPercentage}% Off`}</DiscountTag>
                              ) : null}
                            </Flex>
                          </Col>
                        </StyledRow>
                      </Col>
                    </Row>
                  );
                } else {
                  return (
                    <Row key={index}>
                      <StyledCol span={8}>
                        <PlanDescription>{item.label}</PlanDescription>
                      </StyledCol>
                      <StyledCol span={16}>
                        <Flex justifyContent="flex-start">
                          {index === 0 && <PlanValue>{item.value}</PlanValue>}
                          {index === 1 && (
                            <PlanValue>
                              {data?.ingestion?.priceSchemes[0]?.price}
                            </PlanValue>
                          )}
                          {index === 2 && (
                            <Tooltip
                              placement="bottom"
                              overlayStyle={{ width: 280 }}
                              overlayClassName={styles.planTooltip}
                              title={
                                data?.jobs.priceSchemes && (
                                  <StorageTable
                                    data={data?.jobs.priceSchemes}
                                  />
                                )
                              }
                            >
                              <Typography.P3
                                className={styles.headingTags}
                                weight={theme.fontWeightSemibold}
                                margin="0px"
                                color={theme.primary600}
                              >
                                {item.value}
                              </Typography.P3>
                            </Tooltip>
                          )}
                        </Flex>
                      </StyledCol>
                    </Row>
                  );
                }
              })}
            </div>
            <Flex justifyContent="flex-start" padding="16px 0 8px 0px">
              <AntSwitch defaultChecked={includeBi} onChange={handleBiChange} />
              <Typography.P2 margin="0px 10px">
                Business Intelligence
              </Typography.P2>
              <PlanTag color={includeBi ? true : false}>
                Monthly Subscription
              </PlanTag>
            </Flex>
            {includeBi ? (
              <BiContainer type={bipricingPlan}>
                <BiTitle>
                  {bipricingPlan === "USER" ? (
                    <div>
                      {data?.bi?.priceSchemes[0]?.label}
                      <BiSubtitle>
                        {`${data?.bi?.priceSchemes[0]?.specifications}`}
                      </BiSubtitle>
                    </div>
                  ) : (
                    <>
                      <BiIcon>
                        <Image
                          src={trialIcons.smallInstance}
                          alt="upgrade"
                          width={49}
                          height={49}
                        />
                      </BiIcon>
                      <div>
                        {data?.bi?.priceSchemes[0]?.price}
                        <BiSubtitle>
                          {`${data?.bi?.priceSchemes[0]?.label} , ${data?.bi?.priceSchemes[0]?.specifications}`}
                        </BiSubtitle>
                      </div>
                    </>
                  )}
                </BiTitle>
              </BiContainer>
            ) : (
              <EmptyStateDiv />
            )}

            <AntButton
              size="md"
              fullWidth
              variant="primary"
              handleClick={() => handleAddACard()}
              leftIcon={isLoading ? "" : billingIcons.arrowRightWhite}
              loading={isLoading}
            >
              Continue to add card
            </AntButton>
          </>
        )}
      </div>
    </>
  );
};
