import React, { useEffect, useState } from "react";
import { AppProps } from "next/app";
import { DefaultLayout } from "../layouts";
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "../styles/root.css";
import "../styles/index.css";
import "../styles/antStyles.css";
import "antd/dist/reset.css";
import { useRouter } from "next/router";
import { ROUTES } from "../constants/routes";
import { isUserFromSupportedBrowser } from "../helpers/validBrowser";
import { wrapper } from "../redux/store";
import StyledComponentsRegistry from "../../registry";
import { Flex } from "@/styles/styled/styled";
import FivexLoader from "@/components/loader/FivexLoader";
import { windowsScrollbarStyle } from "@/helpers/common";
import { IntercomProvider } from "react-use-intercom";
import { preloadIcons } from "@/utils/helper";
import { useDispatch } from "react-redux";
import { setHeartbeatStatus } from "@/redux/IDESlice";
import { routeNames } from "@/constants/routes/routeNames";
import LoadingBar from "react-top-loading-bar";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbook } from "@/helpers/growthBook";

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [layoutProps, setLayoutProps] = useState({ [Component.name]: {} });
  const getLayoutProps = (newProps: any = {}) => {
    setLayoutProps((prevProps) => ({
      ...prevProps,
      [Component.name]: { ...prevProps[Component.name], ...newProps },
    }));
  };
  const [cssLoaded, setCssLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setProgress(40);
    });

    router.events.on("routeChangeComplete", () => {
      setProgress(100);
    });
  }, []);

  useEffect(() => {
    // Preload the images of the platform for faster loading
    preloadIcons();
  }, []);

  useEffect(() => {
    // Check if CSS is loaded
    const isCssLoaded = () => {
      const styleSheets = document.styleSheets;
      for (const styleSheet of styleSheets) {
        if (styleSheet.href && !styleSheet.href?.includes("css")) {
          return false;
        }
      }
      return true;
    };

    // Check CSS load status periodically
    const checkCssLoaded = () => {
      if (isCssLoaded()) {
        setCssLoaded(true);
      } else {
        setTimeout(checkCssLoaded, 50); // Check again after 50ms
      }
    };

    checkCssLoaded();
  }, []);

  useEffect(() => {
    windowsScrollbarStyle();
    if (!isUserFromSupportedBrowser()) {
      router.push(ROUTES.UnSupportedBrowser.path);
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      // Check for Cmd + s (Meta for macOS) or Ctrl + s (Control for Windows/Linux)
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault(); // Prevent the default action
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (router.asPath !== routeNames.ide) {
      if (sessionStorage.getItem("timeouts")) {
        const timeouts = JSON.parse(
          sessionStorage.getItem("timeouts") as string,
        );
        timeouts.forEach(clearTimeout);
        dispatch(
          setHeartbeatStatus({
            heartbeatStatus: null,
          }),
        );
        sessionStorage.removeItem("timeouts");
      }
    }
  }, [router.asPath]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <IntercomProvider
        appId={`${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}`}
        autoBoot
      >
        <GoogleOAuthProvider
          clientId={`${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`}
        >
          <StyledComponentsRegistry>
            <LoadingBar
              color={"linear-gradient(90deg, #844BF6 0%, #D077ED 100%)"}
              progress={progress}
              waitingTime={400}
              height={4}
              onLoaderFinished={() => {
                setProgress(0);
              }}
            />
            <ThemeProvider theme={theme}>
              {cssLoaded ? (
                <DefaultLayout layoutProps={layoutProps[Component.name]}>
                  <Component {...pageProps} setLayoutProps={getLayoutProps} />
                </DefaultLayout>
              ) : (
                <Flex height={"100vh"}>
                  <FivexLoader />
                </Flex>
              )}
            </ThemeProvider>
          </StyledComponentsRegistry>
        </GoogleOAuthProvider>
      </IntercomProvider>
    </GrowthBookProvider>
  );
};

export default wrapper.withRedux(MyApp);
