import { useSelector } from "react-redux";
import { Resource, Action } from "./types";
import checkPermissionPure from "../../utils/checkPermissionPure";

type UsePermissionOptions = {
  id?: string;
};

export function usePermission(
  resource: Resource,
  action: Action,
  options: UsePermissionOptions = {},
): boolean {
  const permissions = useSelector((state: any) => state?.user?.permissions);
  return checkPermissionPure(permissions, resource, action, options);
}
