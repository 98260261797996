import styled from "styled-components";
import { FlexProps } from "./types";
import theme from "../theme";

export const Flex = styled("div")<FlexProps>(
  ({
    justifyContent,
    alignItems,
    wrap,
    flexDirection,
    gap,
    width,
    height,
    padding,
    margin,
  }) => ({
    display: "flex",
    flexDirection: flexDirection || "row",
    alignItems: alignItems || "center",
    justifyContent: justifyContent || "center",
    flexWrap: wrap || "wrap",
    gap: gap || 0,
    width,
    height,
    padding,
    margin,
  }),
);

type CardProps = {
  width?: string;
  height?: string;
  align?: string;
  padding?: string;
};

export const CardComponent = styled("div")<CardProps>(
  ({ width, height, align, padding }) => ({
    width: width || "450px",
    height: height || "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "4px",
    border: `1px solid ${theme.primary400}`, // Fix syntax error
    background: theme.textWhite, // Fix syntax error
    alignItems: align,
    padding,
  }),
);
