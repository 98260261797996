import { camelToSnakeUpperCase } from "@/utils/stringUtils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// types.ts

export type PermissionInputs = {
  [fieldId: string]: any;
};

export type ModulePermission = {
  type: string;
  inputs?: PermissionInputs;
};

export type PermissionsState = {
  [moduleId: string]: ModulePermission;
};

export type MetadataInputOption = {
  id: string;
  name?: string;
  label?: string;
};

export type MetadataInput = {
  id: string;
  label: string;
  type: string;
  options?: MetadataInputOption[];
};

export type MetadataOption = {
  id: string;
  label: string;
  input?: MetadataInput[];
};

export type MetadataItem = {
  id: string;
  label: string;
  type: string;
  options: MetadataOption[];
};

export type RoleState = {
  metadata: MetadataItem[];
  roles: any[];
  permissions: PermissionsState;
  selectedRole: {
    id: string;
    roleType: "PRE-BUILT" | null;
  } | null;
  revalidateRoles: boolean;
};

const initialState: RoleState = {
  metadata: [],
  roles: [],
  permissions: {},
  selectedRole: null,
  revalidateRoles: false,
};

export const rolesStore = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setSelectedRole: (
      state,
      action: PayloadAction<{
        id: string;
        roleType: "PRE-BUILT" | null;
      } | null>,
    ) => {
      state.selectedRole = action.payload;
    },
    // Existing action to set metadata
    setMetadata: (state, action: PayloadAction<MetadataItem[]>) => {
      state.metadata = action.payload;
    },

    // Initialize permissions based on metadata
    initializePermissions: (state, action: PayloadAction<MetadataItem[]>) => {
      const metadata = action.payload;
      const permissions: PermissionsState = {};

      metadata.forEach((item) => {
        const hasCustomInputs = item.options.some(
          (option) =>
            option.id === "CUSTOM" && option.input && option.input.length > 0,
        );

        // Convert module ID to UPPERCASE_SNAKE_CASE
        const moduleId = item.id;

        permissions[moduleId] = {
          type: "NO_ACCESS",
          ...(hasCustomInputs && { inputs: {} }),
        };
      });

      state.permissions = permissions;
    },

    // Reset permissions to default values
    resetPermissions: (state) => {
      const permissions: PermissionsState = {};

      state.metadata.forEach((item) => {
        const hasCustomInputs = item.options.some(
          (option) =>
            option.id === "CUSTOM" && option.input && option.input.length > 0,
        );

        permissions[item.id.toLowerCase()] = {
          type: "NO_ACCESS",
          ...(hasCustomInputs && { inputs: {} }),
        };
      });

      state.permissions = permissions;
    },

    // Set access type for a module
    setAccessType: (
      state,
      action: PayloadAction<{ moduleId: string; accessType: string }>,
    ) => {
      const { moduleId, accessType } = action.payload;

      // Only reset inputs if they exist and accessType is not 'CUSTOM'
      if (state.permissions[moduleId]?.inputs && accessType !== "CUSTOM") {
        state.permissions[moduleId].inputs = undefined;
      }

      if (state.permissions[moduleId]) {
        state.permissions[moduleId].type = accessType;
      }
    },

    // Set input value for a custom field
    setInputValue: (
      state,
      action: PayloadAction<{ moduleId: string; fieldId: string; value: any }>,
    ) => {
      const { moduleId, fieldId, value } = action.payload;

      if (!state.permissions[moduleId]) {
        state.permissions[moduleId] = { type: "CUSTOM", inputs: {} };
      }

      if (!state.permissions[moduleId].inputs) {
        state.permissions[moduleId].inputs = {};
      }

      state.permissions[moduleId].type = "CUSTOM";
      state.permissions[moduleId].inputs[fieldId] = value;
    },

    // Load fetched permissions and convert them to the required format for a role
    loadRolePermissions: (state, action: PayloadAction<PermissionsState>) => {
      const fetchedPermissions = action.payload;
      const convertedPermissions: PermissionsState = {};

      Object.entries(fetchedPermissions).forEach(
        ([moduleId, modulePermissions]) => {
          // Convert moduleId to UPPER_SNAKE_CASE
          const snakeCaseModuleId = camelToSnakeUpperCase(moduleId);

          // Ensure modulePermissions is not null
          const safeModulePermissions = modulePermissions ?? {
            type: "NO_ACCESS",
          };

          const { type, ...rest } = safeModulePermissions as {
            type: string;
            view?: Array<{ id: string }>;
            edit?: Array<{ id: string }>;
            hasCreateAccess?: boolean;
          };

          // Initialize converted module permissions
          const convertedModulePermissions: any = { type };

          if (type === "CUSTOM") {
            // Convert 'view' and 'edit' to 'VIEW_ACCESS' and 'EDIT_ACCESS'
            const inputs: any = {};

            if (rest.view) {
              inputs.VIEW_ACCESS = rest.view.map((item: any) => item.id);
            }

            if (rest.edit) {
              inputs.EDIT_ACCESS = rest.edit.map((item: any) => item.id);
            }

            if (rest.hasCreateAccess !== undefined) {
              inputs.HAS_CREATE_ACCESS_FLAG = rest.hasCreateAccess;
            }

            convertedModulePermissions.inputs = inputs;
          }

          convertedPermissions[snakeCaseModuleId] = convertedModulePermissions;
        },
      );

      // Replace the existing permissions with the converted permissions
      state.permissions = convertedPermissions;
    },

    setRevalidateRoles: (state, action: PayloadAction<boolean>) => {
      state.revalidateRoles = action.payload;
    },
    setRolesList: (state, action: PayloadAction<any[]>) => {
      state.roles = action.payload;
    },
  },
});

export const {
  setSelectedRole,
  setMetadata,
  initializePermissions,
  resetPermissions,
  setAccessType,
  setInputValue,
  loadRolePermissions,
  setRevalidateRoles,
  setRolesList,
} = rolesStore.actions;

export default rolesStore.reducer;
