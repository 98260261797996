import { GENERAL_CONFIG } from "@/constants/general";
import checkPermissionPure from "@/utils/checkPermissionPure";

export default function setDefaultLandingPageInSessionStorage(
  defaultLandingPage: string,
  permissions: any,
) {
  const isAccess = GENERAL_CONFIG.find((item) => {
    return item.value === defaultLandingPage;
  })?.rbac;

  if (isAccess && checkPermissionPure(permissions, isAccess, "view")) {
    sessionStorage.setItem("defaultLandingPage", defaultLandingPage);
    return true;
  } else {
    sessionStorage.setItem("defaultLandingPage", "ide");
    return false;
  }
}
