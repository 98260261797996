import { apiEndpoints } from "@/constants/api";
import { getFetcher } from "@/services/onboarding";
import { VENDOR_SETUP_STATUS } from "@/constants/signup";
import { ROUTES } from "../constants/routes";
import { clearRedirectUrl, getRedirectUrl } from "./cookieStorage";
import { mapRoutes } from "@/constants/landingPage";

export const checkStackStatus = async () => {
  const res = await getFetcher(apiEndpoints.stackSetupStatus);
  if (res?.status === "SUCCESS") {
    const vendorSetupStatus = res.data.workspaceSetup.vendorSetupStatus;
    if (VENDOR_SETUP_STATUS?.includes(vendorSetupStatus.toLowerCase())) {
      if (vendorSetupStatus.toLowerCase() === "complete") {
        if (getRedirectUrl()) {
          const redirectUrl = getRedirectUrl();
          clearRedirectUrl();
          return redirectUrl;
        }
        if (window.location.pathname === ROUTES.Provisioning.path) {
          const route = sessionStorage.getItem("defaultLandingPage");
          return mapRoutes[route || "ide"];
        } else return `${window.location.pathname}${window.location.search}`;
      } else if (vendorSetupStatus.toLowerCase() === "in_progress") {
        return ROUTES.Provisioning.path;
      } else if (vendorSetupStatus.toLowerCase() === "not_created") {
        return ROUTES.SetupWarehouse.path;
      } else {
        return ROUTES.SetupWarehouse.path;
      }
    }
  }
};
