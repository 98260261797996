export const GENERAL_CONFIG = [
  {
    label: "IDE",
    value: "ide",
    order: 1,
    rbac: "ide",
  },
  {
    label: "Ingestion",
    value: "ingestion",
    order: 2,
    rbac: "ingestion",
  },
  {
    label: "Jobs",
    value: "jobs",
    order: 3,
    rbac: "jobs",
  },
  {
    label: "BI",
    value: "bi",
    order: 4,
    rbac: "bi",
  },
  {
    label: "Architecture",
    value: "architecture",
    order: 5,
    rbac: "architecture",
  },
  {
    label: "Utilization",
    value: "utilization",
    order: 6,
    rbac: "utilization",
  },
  {
    label: "Team",
    value: "teams",
    order: 7,
    rbac: "teams",
  },
];
