import { trialIcons } from "@/constants/icons/trial";
import { styled } from "styled-components";

export const TrialBackground = styled("div")(() => ({
  background: `url(${trialIcons.blurredBackground})`,
  position: "fixed",
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundPosition: "center",
  zIndex: 1,
  top: 0,
  left: 0,
}));
