import { useEffect, useState } from "react";
import { emitterTypes } from "@/utils/types";
import { emitter } from "@/utils/emitter";
import Typography from "@/uiCore/typography";
import theme from "@/styles/theme";
import { FlexContainer, StyledTypography, MarginDiv } from "./styles";
import NextImage from "@/uiCore/image";
import { navigationIcons } from "@/constants/icons/navigations";
import AntButton from "@/uiCore/button";
import { commonIcons } from "@/constants/icons/common";
import styles from "./index.module.css";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { putFetcher } from "@/services/user";
import { apiEndpoints } from "@/constants/api";

export const Jumpstart = () => {
  const [visible, setVisible] = useState(false);
  const { jumpstart } = apiEndpoints;

  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  )?.companyDetails;

  const jumpStartUrl = companyData?.jumpStartUrl;
  const jumpstartEnabled = companyData?.showJumpStartIcon;

  const updateModalFlag = async () => {
    emitter.emit(emitterTypes.CLOSE_JUMPSTART_MODAL, true);
    await putFetcher(jumpstart, { showJumpStartModal: false });
  };
  useEffect(() => {
    emitter.on(emitterTypes.OPEN_JUMPSTART_MODAL, (value: boolean) => {
      setVisible(value);
    });
    return () => {
      emitter.off(emitterTypes.OPEN_JUMPSTART_MODAL, () => {
        setVisible(false);
      });
    };
  }, []);
  return (
    visible && (
      <Modal
        title=""
        closable={true}
        maskClosable={true}
        onCancel={() => {
          if (jumpstartEnabled) updateModalFlag();
          setVisible(false);
        }}
        visible={visible}
        footer={null}
        width={784}
      >
        <FlexContainer>
          <Typography.H2 weight={theme.fontWeightBold} margin="16px 0 0 0">
            48-Hour Jumpstart
          </Typography.H2>
          <StyledTypography color={theme.textSecondary} margin="8px 0 0 0">
            Let us build out an end-to-end business use case for you in 48 hours
            for free
          </StyledTypography>
          <NextImage
            src={navigationIcons.jumpstartContent}
            alt="jumpstart"
            className={styles.jumpstartImage}
            width={684}
            height={300}
          />
          <MarginDiv>
            <AntButton
              rightIcon={commonIcons?.arrowRightWhite}
              margin="8px 0 0 0"
              size="md"
              variant="primary"
              handleClick={() => {
                if (jumpstartEnabled) updateModalFlag();
                setVisible(false);
                window.open(jumpStartUrl, "_blank");
              }}
            >
              Request a spot
            </AntButton>
          </MarginDiv>
        </FlexContainer>
      </Modal>
    )
  );
};
