import React, { ChangeEvent, useRef, useState } from "react";
import { Input } from "antd";
import styled from "styled-components";
import theme from "../../styles/theme";
import {
  InputSizes,
  getBackgroundColor,
  getBorderColor,
  getFontSize,
  getHeight,
  getHoverBorderColor,
  getPadding,
  getTextColor,
} from "./helper";
import Typography from "../typography";
import NextImage from "../image";
import { commonIcons } from "@/constants/icons/common";

type InputProps = {
  value?: string | "";
  size?: InputSizes;
  label?: any;
  inactive?: boolean;
  placeholder?: string;
  prefixIcon?: string;
  suffixIcon?: string;
  type?: "text" | "number" | "password";
  maxLength?: number;
  error?: string;
  width?: string;
  tooltipMessage?: string;
  description?: string;
  margin?: string;
  readOnly?: boolean;
  status?: string;
  allowClear?: boolean;
  borderRadius?: string;
  onBlur?: (value: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (value: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (value: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (value: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (value: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (value: ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (value: ChangeEvent<HTMLInputElement>) => void;
  onCopy?: (value: ChangeEvent<HTMLInputElement>) => void;
  onCut?: (value: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (value: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (value: ChangeEvent<HTMLInputElement>) => void;
  suffixIconAction?: () => void;
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  mandatory?: boolean;
  background?: string;
  isBold?: boolean;
  ref?: any;
};
type DescriptionProps = {
  description: string;
};

type LabelTooltipProps = {
  label: string;
  tooltipMessage?: string;
  mandatory?: boolean;
  isBold?: boolean;
};

type ErrorMessageProps = {
  error: string;
};

const Description: React.FC<DescriptionProps> = ({ description }) => (
  <Typography.P3 color={theme.neutral900} margin="0 0 8px 0">
    {description}
  </Typography.P3>
);

const FlexDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 8px;
`;

const LabelTooltip: React.FC<LabelTooltipProps> = ({
  label,
  tooltipMessage,
  mandatory,
  isBold,
}) => (
  <FlexDiv>
    <Typography.P2
      weight={isBold ? theme.fontWeightSemibold : theme.fontWeightRegular}
    >
      {label}
      {mandatory && <span style={{ color: theme.error600 }}>*</span>}
    </Typography.P2>
    {tooltipMessage && (
      <NextImage src={commonIcons.info} alt="info" tooltip={tooltipMessage} />
    )}
  </FlexDiv>
);

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => (
  <Typography.P3
    weight={theme.fontWeightRegular}
    margin="8px 0 0"
    color={theme.error600}
  >
    {error}
  </Typography.P3>
);

const StyledInput = styled(Input)<InputProps>(
  ({
    size = "sm",
    error = "",
    inactive = false,
    value = "",
    margin,
    width,
    background,
    borderRadius,
  }) => ({
    fontSize: getFontSize(size),
    fontWeight: theme.fontWeightRegular,
    height: getHeight(size),
    width: width ? width : "100%",
    background: getBackgroundColor(inactive, error, background),
    padding: getPadding(size),
    borderRadius: borderRadius ?? theme.inputBorderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none !important",
    margin: margin ? margin : 0,
    color: getTextColor(value, inactive),
    border: `1px solid ${getBorderColor(error)}`,
    "&:hover": {
      border: `1px solid ${getHoverBorderColor(error)} !important`,
    },
    "&:focus-within": {
      border: `1px solid ${theme.primary400} !important`,
    },
  }),
);

const Wrapper = styled("div")<{ width?: string; margin?: string }>`
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

const AntInput: React.FC<InputProps> = ({
  inactive = false,
  placeholder = "",
  value = "",
  size,
  label,
  prefixIcon = "",
  suffixIcon = "",
  suffixIconAction = () => {},
  error,
  tooltipMessage = "",
  description,
  maxLength,
  type,
  margin,
  width,
  allowClear,
  onChange,
  mandatory,
  background,
  isBold,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };
  const inputRef = useRef<any | null>(null);

  const toggleShowPassword = () => {
    const val = inputRef.current;
    if (val) {
      const currentPos = val.input.selectionStart;
      setShowPassword(!showPassword);
      // Restoring the cursor position after a brief delay
      setTimeout(() => {
        if (val.input) {
          val.input.selectionStart = currentPos;
          val.input.selectionEnd = currentPos;
        }
      }, 0);
    }
  };

  return (
    <Wrapper margin={margin} width={width}>
      {label && (
        <LabelTooltip
          label={label}
          isBold={isBold}
          tooltipMessage={tooltipMessage}
          mandatory={mandatory}
        />
      )}
      {description && <Description description={description} />}
      <StyledInput
        {...rest}
        ref={inputRef}
        size={size}
        disabled={inactive}
        placeholder={placeholder}
        value={value}
        allowClear={allowClear}
        onChange={handleChange}
        background={background}
        error={error}
        type={type === "password" && showPassword ? "text" : type}
        prefix={prefixIcon && <NextImage src={prefixIcon} alt={prefixIcon} />}
        suffix={
          type === "password" ? (
            <NextImage
              src={
                showPassword
                  ? commonIcons["hide-password"]
                  : commonIcons["show-password"]
              }
              alt="password-eye"
              onClick={() => toggleShowPassword()}
              className="cursor-pointer"
            />
          ) : (
            suffixIcon && (
              <NextImage
                src={suffixIcon}
                alt={suffixIcon}
                className="cursor-pointer"
                onClick={() => suffixIconAction()}
              />
            )
          )
        }
        maxLength={maxLength}
      />
      {error && <ErrorMessage error={error} />}
    </Wrapper>
  );
};

export default AntInput;
