import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { Resource, Action } from "./types";
import { usePermission } from "./usePermission";

type ProtectProps = {
  resource: Resource;
  action: Action;
  options?: {
    id?: string;
  };
  children: ReactNode;
  fallback?: ReactNode;
  redirectTo?: string;
};

export default function Protect({
  resource,
  action,
  options,
  fallback = null,
  redirectTo,
  children,
}: ProtectProps) {
  const [isChecking, setIsChecking] = useState(true); // Track loading state
  const hasAccess = usePermission(resource, action, options);
  const permissions = useSelector((state: any) => state?.user?.permissions);
  const router = useRouter();

  useEffect(() => {
    // Wait for permissions to load
    if (permissions !== undefined && permissions !== null) {
      setIsChecking(false);
    }
  }, [permissions]);

  useEffect(() => {
    if (!isChecking && !hasAccess && redirectTo) {
      router.replace(redirectTo);
    }
  }, [isChecking, hasAccess, redirectTo, router]);

  if (isChecking) {
    return null;
  }

  if (!hasAccess) {
    return fallback;
  }

  return children;
}
