import styled from "styled-components";
import theme from "../../styles/theme";

export const NavbarWrapper = styled("div")(() => ({
  padding: "20px",
  background: theme.neutral900,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const DataAppNavbarWrapper = styled("div")`
  padding: 20px;
  background: ${theme.neutral900};
  height: 100vh;
  display: flex;
  flex-direction: column;
  animation: fadeInOut 0.5s ease-in-out 0s 1 normal;

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
