import styled from "styled-components";
import theme from "../../../styles/theme";

export const NaviagationsWrapper = styled("div")<{
  active: boolean;
  enabled: boolean;
  isGradient?: boolean;
}>(({ active, enabled, isGradient }) => ({
  gap: "8px",
  padding: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  background: active ? theme.primary800 : "transparent",
  borderRadius: "8px",
  cursor: enabled ? "pointer" : "default",
  opacity: enabled ? 1 : 0.5,
  width: "100%",
  margin: "4px 0",
  "&:hover": {
    background: isGradient
      ? "linear-gradient(90deg, #F46C4B 0%, #F64393 35.06%, #984ADB 70.97%, #4566ED 100%)"
      : !active
        ? theme.neutral700
        : theme.primary900,
  },
}));
